import React, { Component } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import * as R from 'ramda';
import moment from 'moment';
import { withFormik, Field } from 'formik';

import { I18n } from 'react-redux-i18n';

import { Ionicons as Icon } from '@expo/vector-icons';

import { Grid, Row, Col } from '../Components/Grid';

// import ScanID from './ScanID';
// import ContactInfo from './ContactInfo';
// import UploadTaxReturn from './Income/UploadTaxReturn';
// import RetrieveTaxReturn from './Income/RetrieveTaxReturn';
// import IRS4506T from './Income/IRS4506T';
// import IRS4506TSignature from './Income/IRS4506TSignature';
// import OtherIncome from './Income/OtherIncome';
// import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

// import TopNav from '../Components/TopNav';
import FullButton from '../Components/FullButton';

import {
  getBase,
  getPersonId,
  hasDocument,
  personHasIncome,
  isNumeric,
} from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Colors, Metrics } from '../Themes';

import {
  RENTAL_PROGRAM_CODE,
  YES_OPTION,
  NO_OPTION,
} from '../Config/ApplicationConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from '../Components/Footer';

const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfInsurance.code;
const BASE = DATA_STRUCTURE.additionalFunds;

export const OtherFundsScreenComplete = (application, documents) => {
  return true;
};

class OtherFundsScreen extends Component {
  state = {
    currentScreen: null,
    editingIndex: null,
  };

  addingOtherFunds = false;

  deletingInsurance = false;

  componentDidMount() {
    // this.props.loadDocuments(DOCUMENT_TYPE);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
    } = nextProps;

    if (isSubmitting && status === 'addingOtherFunds' && !fetching) {
      console.log('returned from addingOtherFunds');
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        // console.log('DONE editing idx:', application[BASE].length - 1);
        // history.push('CoapplicantIdentificationScreen');
        const lastIndex = application[BASE] ? application[BASE].length - 1 : 0;
        this.handleEdit(lastIndex);
      }
    } else if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        console.log('DONE, navigating to /PoaDesigneeScreen');
        // history.push('CoapplicantIdentificationScreen');
        history.push('/PoaDesigneeScreen');
      }
    }

    // if (this.addingOtherFunds && !fetching) {
    //   this.addingOtherFunds = false;
    //   if (errorText) {
    //     this.setState({ errorText });
    //   } else if (application && application[BASE]) {
    //     console.log(application);
    //     this.setState({ editingIndex: application[BASE].length - 1 });
    //   } else {
    //     this.setState({ errorText: 'Error Adding Household Member' });
    //   }
    // } else if (this.deletingInsurance && !fetching) {
    //   this.deletingInsurance = false;
    // }
  }

  getNextIndex() {
    const { application } = this.props;

    if (application[BASE] && Object.keys(application[BASE]).length) {
      console.log('next', Object.keys(application[BASE]).length);
      return Object.keys(application[BASE]).length;
    }
    return 0;
  }

  // handleCompleteScan = () => {
  //   this.setState({ currentScreen: 'ContactInfo' });
  // };

  // onComplete = () => {
  //   console.log('onComplete');
  //   this.setState({ editingIndex: null, currentScreen: null });
  // };
  // onCancel = () => {
  //   this.setState({ currentScreen: 'RetrieveTaxReturn' });
  // };

  // onScanComplete = () => {
  //   console.log('onScanComplete');
  // };

  // handleGoToScreen = (currentScreen) => {
  //   this.setState({ currentScreen });
  // };

  // handleContactInfoComplete = () => {
  //   const { application } = this.props;
  //   const { editingIndex } = this.state;
  //   // Check to See if we need to go to Income

  //   const householdMember = getBase(application, BASE, editingIndex);
  //   const dateOfBirth = moment(householdMember.dateOfBirth, 'M/D/YYYY');
  //   const age = householdMember.dateOfBirth ? moment().diff(dateOfBirth, 'years') : 0;

  //   if (age < 18 || application.programCode.toString() === RENTAL_PROGRAM_CODE.toString()) {
  //     this.setState({ editingIndex: null, currentScreen: null });
  //   } else {
  //     this.setState({ currentScreen: 'UploadTaxReturn' });
  //   }
  // };

  handleAddInsurance = () => {
    const {
      application,
      updateApplication,
      setStatus,
      setSubmitting,
    } = this.props;

    this.addingOtherFunds = true;
    const newApplication = JSON.parse(JSON.stringify(application));

    if (!newApplication[BASE]) {
      newApplication[BASE] = [];
    }
    newApplication[BASE].push({ id: '-1' });
    console.log('handleAddInsurance', JSON.stringify(newApplication));
    updateApplication(newApplication);
    setStatus('addingOtherFunds');
    setSubmitting(true);
  };

  handleEdit(editingIndex) {
    console.log('editingIndex', editingIndex, typeof editingIndex);

    this.props.history.push(`/OtherFunds/${editingIndex}/Information`);

    // // Force Int
    // if (typeof editingIndex === 'string') {
    //   this.setState({ editingIndex: parseInt(editingIndex) });
    // } else {
    //   this.setState({ editingIndex });
    // }
  }

  handleDelete(personId, key) {
    const { deleteRecord } = this.props;
    console.log(personId, key);
    // Remove application.BASE[idx]
    this.deletingInsurance = true;
    deleteRecord(personId, key);
  }

  renderInsurancePolicies = () => {
    const { application, documents, options } = this.props;

    let idx = 1;
    const members = [];
    const iconStyle = {
      color: 'red',
      marginHorizontal: Metrics.baseMargin,
    };

    Object.keys(application[BASE]).forEach((key) => {
      if (isNumeric(key)) {
        const member = application[BASE][key] || {};
        // const personId = getPersonId(application, BASE, key);
        // const documentUploaded = hasDocument(
        //   documents,
        //   personId,
        //   DOCUMENT_TYPE
        // );

        // Search Options for typeCode
        let typeCode = '';
        options &&
          options.additional_funds_type &&
          Object.keys(options.additional_funds_type).forEach((key) => {
            const ele = options.additional_funds_type[key];
            // console.log('ele', ele);
            // console.log('memeber', member);
            if (
              ele.code &&
              member.typeCode &&
              ele.code.toString() === member.typeCode.toString()
            ) {
              typeCode = ele.value;
            }
          });

        members.push(
          <View
            key={`member_${key}`}
            style={{
              flex: 1,
              flexDirection: 'column',
              borderWidth: 1,
              padding: Metrics.baseMargin,
              marginHorizontal: 0,
              borderColor: Colors.lightGrey,
              marginBottom: Metrics.baseMargin,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={[Fonts.style.normal, { fontWeight: 'bold', flex: 1 }]}
              >
                {typeCode}
              </Text>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa',
                }}
                onPress={this.handleEdit.bind(this, key)}
              >
                <Icon name="md-create" size={20} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa',
                }}
                onPress={this.handleDelete.bind(this, member.id, key)}
              >
                <Icon name="md-trash" size={20} />
              </TouchableOpacity>
            </View>
          </View>,
        );
        idx++;
      }
    });

    return members;
  };

  render() {
    const {
      application,
      initilized,
      fetching,
      history,
      options,
      submitForm,
    } = this.props;

    const { editingIndex, currentScreen, errorText } = this.state;

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    if (
      application &&
      application[BASE] &&
      Object.keys(application[BASE]).length > 0
    ) {
      return (
        <View style={styles.flex}>
          <ScrollView
            ref={(scroll) => {
              this._scroll = scroll;
            }}
            style={styles.scrollViewContainer}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <Grid>
              <Row>
                <Col>
                  <FormattedMessage
                    id="OtherFunds.title"
                    style={Fonts.style.title}
                  />
                  <FormattedMessage
                    id="OtherFunds.subtitle"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {errorView}
                  {this.renderInsurancePolicies()}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t(`OtherFunds.AddAnother`)}
                    onPress={this.handleAddInsurance}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t('form.continueNextStep')}
                    onPress={() => history.push('PoaDesigneeScreen')}
                  />
                </Col>
              </Row>
            </Grid>
            <View style={{ flex: 1 }} />
            <Footer />
          </ScrollView>
        </View>
      );
    }

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="OtherFunds.title"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="receivedAdditionalFundsCode"
                  labelId={`OtherFunds.subtitle`}
                  optionsKey="yes_no"
                  required
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={submitForm}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
      </View>
    );
  }
}

OtherFundsScreen = withFormik({
  mapPropsToValues: (props) => {
    return props.application;
  },
  // isInitialValid: (props) => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { application, history, updateApplication } = props;

    const newApplication = JSON.parse(JSON.stringify(application));
    newApplication.receivedAdditionalFundsCode =
      values.receivedAdditionalFundsCode;

    if (
      values.receivedAdditionalFundsCode &&
      values.receivedAdditionalFundsCode === YES_OPTION
    ) {
      // Yes

      if (!newApplication[BASE]) {
        newApplication[BASE] = [];
      }
      newApplication[BASE].push({ id: '-1' });
      console.log('addingOtherFunds', JSON.stringify(newApplication));
      updateApplication(newApplication);
      setStatus('addingOtherFunds');
    } else {
      // No
      updateApplication(newApplication);
      setStatus('updatingApplication');

      // history.push('PoaDesigneeScreen');
    }
  },
})(OtherFundsScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId },
  document,
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  deleteHouseholdMember: (memberId) =>
    dispatch(ApplicationActions.ApplicationHouseholdMemberDelete(memberId)),
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (memberId, key) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId, key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherFundsScreen);
