import React, { Component, Suspense, lazy } from 'react';
import { Platform, Text, View, TouchableOpacity } from 'react-native';
// import { Router as MyRouter, Route as MyRoute, Link as MyLink } from './Router';
// import Drawer from 'react-native-drawer';
import { ConnectedRouter } from 'connected-react-router';
import Drawer from './Drawer';
import { Router, Route, Link, Switch } from './Router';
import { withGaTracker } from './withGaTracker';

import DrawerContents from './DrawerContents';

import SafeAera from '../Components/SafeAera';
import TopNav from '../Components/TopNav';
import LoadingScreen from '../Containers/LoadingScreen';
import PrivateRoute from './ApplicationRoute';

const HomeScreen = lazy(() => import('../Containers/HomeScreen'));
const LoginScreen = lazy(() => import('../Containers/LoginScreen'));

const ForgotPasswordScreen = lazy(() =>
  import('../Containers/ForgotPasswordScreen'),
);
const ChangePasswordScreen = lazy(() =>
  import('../Containers/ChangePasswordScreen'),
);

const VerifyOtpScreen = lazy(() => import('../Containers/VerifyOtpScreen'));
const CreateAccountScreen = lazy(() =>
  import('../Containers/CreateAccountScreen'),
);

const SelectApplicationScreen = lazy(() =>
  import('../Containers/SelectApplicationScreen'),
);

const GetStartedDocListScreen = lazy(() =>
  import('../Containers/GetStartedDocListScreen'),
);
const ChooseProgramScreen = lazy(() =>
  import('../Containers/ChooseProgramScreen'),
);
const DoIQualifyScreen = lazy(() => import('../Containers/DoIQualifyScreen'));
const YouDontQualifyScreen = lazy(() =>
  import('../Containers/YouDontQualifyScreen'),
);
const NotifyMeOfOtherProgramsScreen = lazy(() =>
  import('../Containers/NotifyMeOfOtherProgramsScreen'),
);
const WhyDontIQualifyScreen = lazy(() =>
  import('../Containers/WhyDontIQualifyScreen'),
);

const ProgramReferralScreen = lazy(() =>
  import('../Containers/ProgramReferralScreen'),
);

const ApplicantIdentificationScreen = lazy(() =>
  import('../Containers/ApplicantIdentificationScreen'),
);
const ApplicantInformationScreen = lazy(() =>
  import('../Containers/ApplicantInformationScreen'),
);
const ApplicantIncomeScreen = lazy(() =>
  import('../Containers/ApplicantIncomeScreen'),
);
const ApplicantTaxReturnScreen = lazy(() =>
  import('../Containers/ApplicantTaxReturnScreen'),
);

const CoapplicantExistsScreen = lazy(() =>
  import('../Containers/CoapplicantExistsScreen'),
);

const CoapplicantIdentificationScreen = lazy(() =>
  import('../Containers/CoapplicantIdentificationScreen'),
);
const CoapplicantInformationScreen = lazy(() =>
  import('../Containers/CoapplicantInformationScreen'),
);
const CoapplicantIncomeScreen = lazy(() =>
  import('../Containers/CoapplicantIncomeScreen'),
);
const CoapplicantTaxReturnScreen = lazy(() =>
  import('../Containers/CoapplicantTaxReturnScreen'),
);

const HouseholdMembersScreen = lazy(() =>
  import('../Containers/HouseholdMembersScreen'),
);
const HouseholdMembersIdentificationScreen = lazy(() =>
  import('../Containers/HouseholdMembersIdentificationScreen'),
);
const HouseholdMemberInformationScreen = lazy(() =>
  import('../Containers/HouseholdMemberInformationScreen'),
);
const HouseholdMemberIncomeScreen = lazy(() =>
  import('../Containers/HouseholdMemberIncomeScreen'),
);
const HouseholdMemberTaxReturnScreen = lazy(() =>
  import('../Containers/HouseholdMemberTaxReturnScreen'),
);

const DisabilityProofScreen = lazy(() =>
  import('../Containers/DisabilityProofScreen'),
);

const TenantsScreen = lazy(() => import('../Containers/TenantsScreen'));
const TenantInformationScreen = lazy(() =>
  import('../Containers/TenantInformationScreen'),
);

const PropertyOwnersScreen = lazy(() =>
  import('../Containers/PropertyOwnersScreen'),
);
const PropertyOwnerInformationScreen = lazy(() =>
  import('../Containers/PropertyOwnerInformationScreen'),
);

const DamagedAddressScreen = lazy(() =>
  import('../Containers/DamagedAddressScreen'),
);
const DamagedAddressImagesScreen = lazy(() =>
  import('../Containers/DamagedAddressImagesScreen'),
);

const TemporaryRelocationScreen = lazy(() =>
  import('../Containers/TemporaryRelocationScreen'),
);
const EnvironmentalInformationScreen = lazy(() =>
  import('../Containers/EnvironmentalInformationScreen'),
);

const LienOnPropertyScreen = lazy(() =>
  import('../Containers/LienOnPropertyScreen'),
);
const LienHolderInformation = lazy(() =>
  import('../Containers/LienHolderInformation'),
);

const OwnHomeScreen = lazy(() => import('../Containers/OwnHomeScreen'));
const OwnsLandScreen = lazy(() => import('../Containers/OwnsLandScreen'));

const OwnHomeProofScreen = lazy(() =>
  import('../Containers/OwnHomeProofScreen'),
);
const PrimaryResidenceScreen = lazy(() =>
  import('../Containers/PrimaryResidenceScreen'),
);
const PrimaryResidenceProofScreen = lazy(() =>
  import('../Containers/PrimaryResidenceProofScreen'),
);

const FloodWaterEnterHomeScreen = lazy(() =>
  import('../Containers/FloodWaterEnterHomeScreen'),
);

const RentalInformationScreen = lazy(() =>
  import('../Containers/RentalInformationScreen'),
);

const InsuranceScreen = lazy(() => import('../Containers/InsuranceScreen'));
const InsurancePolicy = lazy(() => import('../Containers/InsurancePolicy'));
const NoInsuranceScreen = lazy(() => import('../Containers/NoInsuranceScreen'));

const OtherFundsScreen = lazy(() => import('../Containers/OtherFundsScreen'));
const OtherFunds = lazy(() => import('../Containers/OtherFunds'));

const PoaDesigneeScreen = lazy(() => import('../Containers/PoaDesigneeScreen'));
const PoaScreen = lazy(() => import('../Containers/PoaScreen'));
const CommsDesigneeScreen = lazy(() =>
  import('../Containers/CommsDesigneeScreen'),
);

const PreferredManagementSolutionScreen = lazy(() =>
  import('../Containers/PreferredManagementSolutionScreen'),
);

const ConsentToShareDataScreen = lazy(() =>
  import('../Containers/ConsentToShareDataScreen'),
);
const CertificationOfCitizenshipScreen = lazy(() =>
  import('../Containers/CertificationOfCitizenshipScreen'),
);

const ApplicationChecklistScreen = lazy(() =>
  import('../Containers/ApplicationChecklistScreen'),
);

const NextStepsKioskScreen = lazy(() =>
  import('../Containers/NextStepsKioskScreen'),
);
const NextStepsScreen = lazy(() => import('../Containers/NextStepsScreen'));
const SatisfactionSurveyScreen = lazy(() =>
  import('../Containers/SatisfactionSurveyScreen'),
);

const SyncStatusScreen = lazy(() => import('../Containers/SyncStatusScreen'));

const SelectMyCaseScreen = lazy(() =>
  import('../Containers/SelectMyCaseScreen'),
);
const SubmitCaseLinkCodeScreen = lazy(() =>
  import('../Containers/SubmitCaseLinkCodeScreen'),
);
const ApplicationStatusScreen = lazy(() =>
  import('../Containers/ApplicationStatusScreen'),
);
const ApplicationStatusScreen1 = lazy(() =>
  import('../Containers/ApplicationStatusScreen1'),
);
const ApplicationStatusScreen2 = lazy(() =>
  import('../Containers/ApplicationStatusScreen2'),
);

const styles = {};

// const drawerStyles = {
//   drawer: { shadowColor: '#000000', shadowOpacity: 0.8, shadowRadius: 3 },
//   main: { paddingLeft: 3 }
// };

export default class Navigation extends Component {
  closeDrawer = () => {
    console.log('close drawer');
    this.drawer.close();
  };

  openDrawer = () => {
    this.drawer.open();
  };

  handleOnChange = (e) => {
    console.log('e', e);
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { history } = this.props;

    const drawerWidth = 300;

    return (
      <ConnectedRouter history={history}>
        <Suspense fallback={<LoadingScreen />}>
          <Drawer
            ref={(ref) => (this.drawer = ref)}
            content={<DrawerContents closeDrawer={this.closeDrawer} />}
          >
            {/* <Drawer
          ref={ref => (this.drawer = ref)}
          type="overlay"
          tapToClose={true}
          acceptPan={true}
          openDrawerOffset={viewport => viewport.width - drawerWidth}
          closedDrawerOffset={-3}
          elevation={2}
          content={<DrawerContents closeDrawer={this.closeDrawer} />}
          styles={{
            drawer: {
              shadowColor: '#000000',
              shadowOpacity: 0.8,
              shadowRadius: 3
            },
            main: { paddingLeft: 3 }
          }}
        > */}
            <SafeAera>
              <TopNav onToggleDrawer={this.openDrawer} />
              {/* your usual react-router v4 routing */}
              <Route exact path="/" component={withGaTracker(HomeScreen)} />
              <Route
                path="/LoginScreen/:changedPassword?"
                component={withGaTracker(LoginScreen)}
              />

              <Route
                path="/ForgotPasswordScreen"
                component={withGaTracker(ForgotPasswordScreen)}
              />
              <Route
                path="/ChangePasswordScreen"
                component={withGaTracker(ChangePasswordScreen)}
              />

              {/* <Route render={() => <View>Miss</View>} /> */}
              <Route
                path="/VerifyOtpScreen"
                component={withGaTracker(VerifyOtpScreen)}
              />
              <Route
                path="/CreateAccountScreen"
                component={withGaTracker(CreateAccountScreen)}
              />

              <Route
                path="/GetStartedDocListScreen"
                component={withGaTracker(GetStartedDocListScreen)}
              />
              <Route
                path="/ChooseProgramScreen"
                component={withGaTracker(ChooseProgramScreen)}
              />
              <Route
                path="/DoIQualifyScreen"
                component={withGaTracker(DoIQualifyScreen)}
              />
              <Route
                path="/YouDontQualifyScreen"
                component={withGaTracker(YouDontQualifyScreen)}
              />
              <Route
                path="/WhyDontIQualifyScreen"
                component={withGaTracker(WhyDontIQualifyScreen)}
              />
              <Route
                exact
                path="/NotifyMeOfOtherProgramsScreen"
                component={NotifyMeOfOtherProgramsScreen}
              />
              <Route
                path="/NotifyMeOfOtherProgramsScreen/LoginScreen"
                component={LoginScreen}
              />
              <Route
                path="/NotifyMeOfOtherProgramsScreen/CreateAccountScreen"
                component={CreateAccountScreen}
              />
              <Route
                path="/NotifyMeOfOtherProgramsScreen/VerifyOtpScreen"
                component={VerifyOtpScreen}
              />

              <Route
                path="/SelectApplicationScreen"
                component={withGaTracker(SelectApplicationScreen)}
              />
              <Route
                path="/ProgramReferralScreen"
                component={withGaTracker(ProgramReferralScreen)}
              />

              <PrivateRoute
                exact
                path="/ApplicantIdentificationScreen"
                component={withGaTracker(ApplicantIdentificationScreen)}
              />
              <Route
                path="/ApplicantIdentificationScreen/LoginScreen"
                component={withGaTracker(LoginScreen)}
              />
              <Route
                path="/ApplicantIdentificationScreen/CreateAccountScreen"
                component={withGaTracker(CreateAccountScreen)}
              />
              <Route
                path="/ApplicantIdentificationScreen/VerifyOtpScreen"
                component={withGaTracker(VerifyOtpScreen)}
              />

              <PrivateRoute
                path="/ApplicantInformationScreen"
                component={withGaTracker(ApplicantInformationScreen)}
              />
              <PrivateRoute
                path="/ApplicantIncomeScreen"
                component={withGaTracker(ApplicantIncomeScreen)}
              />
              <PrivateRoute
                path="/ApplicantTaxReturnScreen"
                component={withGaTracker(ApplicantTaxReturnScreen)}
              />
              <PrivateRoute
                path="/CoapplicantExistsScreen"
                component={withGaTracker(CoapplicantExistsScreen)}
              />
              <PrivateRoute
                path="/CoapplicantIdentificationScreen"
                component={withGaTracker(CoapplicantIdentificationScreen)}
              />
              <PrivateRoute
                path="/CoapplicantInformationScreen"
                component={withGaTracker(CoapplicantInformationScreen)}
              />
              <PrivateRoute
                path="/CoapplicantIncomeScreen"
                component={withGaTracker(CoapplicantIncomeScreen)}
              />
              <PrivateRoute
                path="/CoapplicantTaxReturnScreen"
                component={withGaTracker(CoapplicantTaxReturnScreen)}
              />

              <PrivateRoute
                path="/HouseholdMembersScreen"
                component={withGaTracker(HouseholdMembersScreen)}
              />

              <PrivateRoute
                path="/HouseholdMember/:index/Identification"
                component={withGaTracker(HouseholdMembersIdentificationScreen)}
              />
              <PrivateRoute
                path="/HouseholdMember/:index/Information"
                component={withGaTracker(HouseholdMemberInformationScreen)}
              />
              <PrivateRoute
                path="/HouseholdMember/:index/Income"
                component={withGaTracker(HouseholdMemberIncomeScreen)}
              />
              <PrivateRoute
                path="/HouseholdMember/:index/TaxReturn"
                component={withGaTracker(HouseholdMemberTaxReturnScreen)}
              />

              <PrivateRoute
                path="/DisabilityProofScreen"
                component={withGaTracker(DisabilityProofScreen)}
              />

              <PrivateRoute
                path="/TenantsScreen"
                component={withGaTracker(TenantsScreen)}
              />

              <PrivateRoute
                path="/Tenant/:index/Information"
                component={withGaTracker(TenantInformationScreen)}
              />

              <PrivateRoute
                path="/PropertyOwnersScreen"
                component={withGaTracker(PropertyOwnersScreen)}
              />
              <PrivateRoute
                path="/PropertyOwner/:index/Information"
                component={withGaTracker(PropertyOwnerInformationScreen)}
              />

              <PrivateRoute
                path="/DamagedAddressScreen"
                component={withGaTracker(DamagedAddressScreen)}
              />
              <PrivateRoute
                path="/DamagedAddressImagesScreen"
                component={withGaTracker(DamagedAddressImagesScreen)}
              />
              <PrivateRoute
                path="/TemporaryRelocationScreen"
                component={withGaTracker(TemporaryRelocationScreen)}
              />
              <PrivateRoute
                path="/EnvironmentalInformationScreen"
                component={withGaTracker(EnvironmentalInformationScreen)}
              />
              <PrivateRoute
                path="/LienOnPropertyScreen"
                component={withGaTracker(LienOnPropertyScreen)}
              />
              <PrivateRoute
                path="/LienHolder/:index/Information"
                component={withGaTracker(LienHolderInformation)}
              />
              <PrivateRoute
                path="/OwnHomeScreen"
                component={withGaTracker(OwnHomeScreen)}
              />
              <PrivateRoute
                path="/OwnsLandScreen"
                component={withGaTracker(OwnsLandScreen)}
              />
              <PrivateRoute
                path="/OwnHomeProofScreen"
                component={withGaTracker(OwnHomeProofScreen)}
              />
              <PrivateRoute
                path="/PrimaryResidenceScreen"
                component={withGaTracker(PrimaryResidenceScreen)}
              />
              <PrivateRoute
                path="/PrimaryResidenceProofScreen"
                component={withGaTracker(PrimaryResidenceProofScreen)}
              />
              <PrivateRoute
                path="/FloodWaterEnterHomeScreen"
                component={withGaTracker(FloodWaterEnterHomeScreen)}
              />
              <PrivateRoute
                path="/RentalInformationScreen"
                component={withGaTracker(RentalInformationScreen)}
              />
              <PrivateRoute
                path="/InsuranceScreen"
                component={withGaTracker(InsuranceScreen)}
              />
              <PrivateRoute
                path="/Insurance/:index/Information"
                component={withGaTracker(InsurancePolicy)}
              />
              <PrivateRoute
                path="/NoInsuranceScreen"
                component={withGaTracker(NoInsuranceScreen)}
              />

              <PrivateRoute
                path="/OtherFundsScreen"
                component={withGaTracker(OtherFundsScreen)}
              />
              <PrivateRoute
                path="/OtherFunds/:index/Information"
                component={withGaTracker(OtherFunds)}
              />

              <PrivateRoute
                path="/PoaDesigneeScreen"
                component={withGaTracker(PoaDesigneeScreen)}
              />
              <PrivateRoute
                path="/PoaScreen"
                component={withGaTracker(PoaScreen)}
              />
              <PrivateRoute
                path="/CommsDesigneeScreen"
                component={withGaTracker(CommsDesigneeScreen)}
              />

              <PrivateRoute
                path="/PreferredManagementSolutionScreen"
                component={withGaTracker(PreferredManagementSolutionScreen)}
              />

              <PrivateRoute
                path="/ConsentToShareDataScreen"
                component={withGaTracker(ConsentToShareDataScreen)}
              />
              <PrivateRoute
                path="/CertificationOfCitizenshipScreen"
                component={withGaTracker(CertificationOfCitizenshipScreen)}
              />
              <PrivateRoute
                path="/ApplicationChecklistScreen"
                component={withGaTracker(ApplicationChecklistScreen)}
              />

              <Route
                path="/NextStepsKioskScreen"
                component={withGaTracker(NextStepsKioskScreen)}
              />
              <PrivateRoute
                path="/NextStepsScreen"
                component={withGaTracker(NextStepsScreen)}
              />
              <Route
                path="/SatisfactionSurveyScreen"
                component={withGaTracker(SatisfactionSurveyScreen)}
              />

              <Route
                path="/SyncStatusScreen"
                component={withGaTracker(SyncStatusScreen)}
                exact
              />

              <Route
                path="/SyncStatusScreen/VerifyOtpScreen"
                component={withGaTracker(VerifyOtpScreen)}
              />

              {/* Status Tracker Screens */}
              <Route
                path="/SelectMyCaseScreen"
                exact
                component={withGaTracker(SelectMyCaseScreen)}
              />
              <Route
                path="/SelectMyCaseScreen/LoginScreen"
                component={withGaTracker(LoginScreen)}
              />
              <Route
                path="/SelectMyCaseScreen/CreateAccountScreen"
                component={withGaTracker(CreateAccountScreen)}
              />
              <Route
                path="/SelectMyCaseScreen/VerifyOtpScreen"
                component={withGaTracker(VerifyOtpScreen)}
              />
              <Route
                exact
                path="/SubmitCaseLinkCodeScreen"
                component={withGaTracker(SubmitCaseLinkCodeScreen)}
              />
              <Route
                path="/SubmitCaseLinkCodeScreen/LoginScreen"
                component={withGaTracker(LoginScreen)}
              />
              <Route
                path="/SubmitCaseLinkCodeScreen/CreateAccountScreen"
                component={withGaTracker(CreateAccountScreen)}
              />
              <Route
                path="/SubmitCaseLinkCodeScreen/VerifyOtpScreen"
                component={withGaTracker(VerifyOtpScreen)}
              />

              <Route
                path="/ApplicationStatusScreen"
                exact
                component={withGaTracker(ApplicationStatusScreen)}
              />

              <Route
                path="/ApplicationStatusScreen1"
                exact
                component={withGaTracker(ApplicationStatusScreen1)}
              />
              <Route
                path="/ApplicationStatusScreen2"
                exact
                component={withGaTracker(ApplicationStatusScreen2)}
              />
              {/* <Route path="/" component={withGaTracker(LaunchScreen} /> */}
              {/* <Route path="/camera" component={withGaTracker(CameraScreen} /> */}
            </SafeAera>
          </Drawer>
        </Suspense>
      </ConnectedRouter>
    );
  }
}
