import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { push } from 'connected-react-router';

import PersistActions from '../Redux/PersistRedux';
import AccountActions from '../Redux/AccountRedux';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

export const getPersist = (state) => state.persist;
export const getLocale = (state) => state.i18n.locale;

export default function* validateToken(api, { force }) {
  const persist = yield select(getPersist);
  const { token, tokenId, account, expires, expiresInSeconds } = persist;
  const { userId } = account || {};

  const loggedIn = !!(token && tokenId && userId);
  console.log('defaultfunction*validateToken -> loggedIn', loggedIn);
  if (!loggedIn) return false;
  // Do we need to refresh the token?
  const now = moment();
  // Check at 50%
  const tokenExpiresThreshold = moment(expires).subtract(
    expiresInSeconds / 2,
    'seconds',
  );

  // No need to check, return true
  if (!force && now.isBefore(tokenExpiresThreshold)) return true;

  try {
    console.log('refreshing token');
    const results = yield call(api.validateToken, userId, token, tokenId);
    console.log('validateToken results', results);
    // Successful Response
    // {
    //     "payload": [
    //         {
    //             "returnValue": "1",
    //             "returnMessage": "Successfully found Token record with this @UserId, @TokenId, and @Token, which has not yet expired.",
    //             "userId": 16,
    //             "isValid": "1",
    //             "isExpired": "0",
    //             "expiresInSeconds": 3600
    //         }
    //     ]
    // }
    if (
      results.data?.payload?.[0]?.isValid === '1' &&
      results.data?.payload?.[0]?.isExpired === '0'
    ) {
      const newExpires = moment().add(
        results.data?.payload?.[0]?.expiresInSeconds,
        'seconds',
      );
      yield put(
        PersistActions.PersistSetToken(
          tokenId,
          token,
          results.data?.payload?.[0]?.expiresInSeconds,
          newExpires.format(),
        ),
      );
      return true;
    }
    // Token did not refresh, log the user out.
    yield put(PersistActions.PersistReset());
    // Save Expires so Home Screen knows
    yield put(PersistActions.PersistSetToken(null, null, null, expires));
    yield put(push('/'));
    return false;
  } catch (error) {
    console.log('error', error.message || error);
    yield put(AccountActions.AccountFailure(error.message || error));
    return false;
  }
}
