import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';

// Styles
import { Fonts } from '../Themes';
import styles from './Styles/ScreenStyles';

class ConsentToShareDataForm extends Component {
  render() {
    const { locale, application } = this.props;

    const isRentalProgram = checkRentalProgram(application);
    console.log(
      'ConsentToShareDataForm -> render -> isRentalProgram',
      isRentalProgram,
    );

    return (
      <View>
        <Row>
          <Col sm={12}>
            {/* <Text
              style={[
                styles.block,
                { textAlign: 'right', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.addressName')}
            </Text>
            <Text style={[styles.block, { textAlign: 'right' }]}>
              {I18n.t('ConsentToShareData.address1')}
            </Text>
            <Text style={[styles.block, { textAlign: 'right' }]}>
              {I18n.t('ConsentToShareData.address2')}
            </Text> */}

            {isRentalProgram && (
              <View>
                <Text
                  style={[
                    Fonts.style.sectionHeader,
                    styles.bold,
                    styles.underline,
                    { fontWeight: Fonts.weight.bold },
                  ]}
                >
                  {I18n.t('ConsentToShareData.title')}
                </Text>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet1')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet2')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet3')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet4')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet5')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet6')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet7')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet8')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet9')}
                  </Text>
                </View>
              </View>
            )}
            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                { fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title2')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.rentalsecton1')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section2')}
            </Text>

            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet10')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet11')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet12')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet13')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet14')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet15')}
              </Text>
            </View>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section3')}
            </Text>

            <Text
              style={[
                styles.bold,
                styles.underline,
                styles.center,
                {
                  ...Fonts.style.sectionHeader,
                  textAlign: 'left',
                  fontWeight: Fonts.weight.bold,
                },
              ]}
            >
              {I18n.t('ConsentToShareData.title3')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section4')}
            </Text>

            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title4')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section5')}
            </Text>

            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title5')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section6')}
            </Text>

            {/* <Text style={[styles.p, { fontWeight: Fonts.weight.bold }]}>
              {I18n.t('ConsentToShareData.addressName')}
            </Text>
            <Text style={styles.block}>
              {I18n.t('ConsentToShareData.address1')}
            </Text>
            <Text style={styles.block}>
              {I18n.t('ConsentToShareData.address2')}
            </Text> */}

            <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title6')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section7')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section8')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section9')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section10')}
            </Text>

            {isRentalProgram && (
              <Text
                style={[
                  Fonts.style.sectionHeader,
                  styles.bold,
                  styles.underline,
                  styles.center,
                  { textAlign: 'left', fontWeight: Fonts.weight.bold },
                ]}
              >
                {I18n.t('ConsentToShareData.title6_1Rental')}
              </Text>
            )}
            {!isRentalProgram && (
              <Text
                style={[
                  Fonts.style.sectionHeader,
                  styles.bold,
                  styles.underline,
                  styles.center,
                  { textAlign: 'left', fontWeight: Fonts.weight.bold },
                ]}
              >
                {I18n.t('ConsentToShareData.title6_1')}
              </Text>
            )}

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section7_1')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section8_1')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section9_1')}
            </Text>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet9_1_1')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet9_1_2')}
              </Text>
            </View>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section10_1')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section11_1')}
            </Text>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet11_1_1')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet11_1_2')}
              </Text>
            </View>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section12_1')}
            </Text>

            {isRentalProgram && (
              <View>
                <Text
                  style={[
                    Fonts.style.sectionHeader,
                    styles.bold,
                    styles.underline,
                    styles.center,
                    { textAlign: 'left', fontWeight: Fonts.weight.bold },
                  ]}
                >
                  {I18n.t('ConsentToShareData.title7')}
                </Text>
                <Text style={styles.p}>
                  {I18n.t('ConsentToShareData.section11')}
                </Text>
                <Text style={styles.p}>
                  {I18n.t('ConsentToShareData.section12')}
                </Text>
                <Text style={styles.p}>
                  {I18n.t('ConsentToShareData.section13')}
                </Text>

                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>1)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet16')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>2)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet17')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>3)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet18')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>4)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet19')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>5)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet20')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>6)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet21')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>7)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet22')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>8)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet23')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>9)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet24')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>10)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet25')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>11)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet26')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>12)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet27')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text style={styles.liBullet}>13)</Text>
                  <Text style={styles.liText}>
                    {I18n.t('ConsentToShareData.bullet28')}
                  </Text>
                </View>
                <View style={styles.liWrapper}>
                  <Text
                    style={{
                      ...styles.liBulletBold,
                      fontFamily: Fonts.type.bold,
                    }}
                  >
                    14)
                  </Text>
                  <Text
                    style={{
                      ...styles.liTextBold,
                      fontFamily: Fonts.type.bold,
                    }}
                  >
                    {I18n.t('ConsentToShareData.bullet29')}
                    {'\n\n'}
                    {I18n.t('ConsentToShareData.section14')}
                  </Text>
                </View>
              </View>
            )}
            {/* <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { textAlign: 'left', fontWeight: Fonts.weight.bold },
              ]}
            >
              {I18n.t('ConsentToShareData.title8')}
            </Text>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section15')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section16')}
            </Text>

            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet30')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet31')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet32')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet33')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet34')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet35')}
              </Text>
            </View>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section17')}
            </Text>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section18')}
            </Text> */}

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.signBelow')}
            </Text>
          </Col>
        </Row>
      </View>
    );
  }
}

const mapStateToProps = ({
  i18n: { locale },
  options: { options },
  app: { initilized },
  persist: { application },
}) => ({
  locale,
  options,
  initilized,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  saveApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsentToShareDataForm);
