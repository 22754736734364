import Immutable from 'seamless-immutable';
import { Platform } from 'react-native';
import * as R from 'ramda';
import diff from 'deep-diff';
import moment from 'moment';

import { push } from 'connected-react-router';

import { call, put, select } from 'redux-saga/effects';
import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';
import CaseActions from '../Redux/CaseRedux';
import { getOptions, getDocTypes, getScreeningQuestions } from './OptionsSagas';

import { isKiosk } from '../Redux/AccountRedux';
import { isNumeric, hasDocument } from '../Lib/Utils';

import {
  ORGIN_CODE_MOBILE,
  ORGIN_CODE_WEB,
  NO_OPTION,
  TRUE_OPTION,
  STATUS_IN_PROGRESS,
  VENDOR_NAME,
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE, {
  DELETE_STRUCTURE,
} from '../Config/DataStructureConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;

const LAND_OWNER_BASE = DATA_STRUCTURE.landOwner;
const POA_BASE = DATA_STRUCTURE.powerOfAttorney;
const COMMUNICATIONS_DESIGNEE_BASE = DATA_STRUCTURE.communicationsDesignee;

const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const TENANTS_BASE = DATA_STRUCTURE.tenants;
const LIEN_HOLDERS_BASE = DATA_STRUCTURE.lien_holders;
const INSURANCE_BASE = DATA_STRUCTURE.insurance;
const ADDITIONAL_FUNDS_BASE = DATA_STRUCTURE.additionalFunds;
const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;

export const getProgramCode = (state) => state.options.programCode;
export const getDocumentState = (state) => state.document;
export const getPersist = (state) => state.persist;
export const getLocale = (state) => state.i18n.locale;

export function* getCases(api) {
  const { account, tokenId, token } = yield select(getPersist);

  const userId = account && account.userId;

  let results = '';
  try {
    results = yield call(api.getCases, userId, tokenId, token);
    // console.log("getCases resuts", JSON.stringify(results.data, null, 2));
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      results = error;
    }
  }
  if (R.path(['data', 'returnValue'], results) === 'True') {
    yield put(CaseActions.CaseSuccess(results.data.payload));
  } else if (R.path(['data', 'returnMessage'], results)) {
    yield put(CaseActions.CaseFailure(results.data.returnMessage));
  } else if (R.path(['data', 'error', 0, 'message'], results)) {
    yield put(CaseActions.CaseFailure(results.data.error[0].message));
  } else {
    yield put(CaseActions.CaseFailure('Unknown Error'));
  }
}

export function* linkCase(api, { userPortalCaseLinkCode }) {
  const { account, tokenId, token } = yield select(getPersist);

  const userId = account && account.userId;

  let results = '';
  try {
    results = yield call(
      api.verifyCaseLinkCode,
      userPortalCaseLinkCode,
      userId,
      tokenId,
      token,
    );
    console.log('linkCase resuts', JSON.stringify(results.data, null, 2));
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      results = error;
    }
  }
  if (R.path(['data', 'payload', 0, 'returnValue'], results) === 'True') {
    yield put(CaseActions.CaseSuccess(results.data.payload[0]));
  } else if (R.path(['data', 'payload', 0, 'returnMessage'], results)) {
    yield put(CaseActions.CaseFailure(results.data.payload[0].returnMessage));
  } else if (R.path(['data', 'error', 0, 'message'], results)) {
    yield put(CaseActions.CaseFailure(results.data.error[0].message));
  } else {
    yield put(CaseActions.CaseFailure('Unknown Error'));
  }
}
