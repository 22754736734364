import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
// import { Ionicons as Icon } from "@expo/vector-icons";
import { I18n } from 'react-redux-i18n';
// import { Ionicons as Icon } from "@expo/vector-icons";
import { Ionicons as Icon } from '@expo/vector-icons';

import styles from './Styles/TopNavStyles';
import ExamplesRegistry from '../Services/ExamplesRegistry';

import { Colors, Metrics } from '../Themes';

export default function TopNavPlaceholder() {
  const title = I18n.t('header.title');
  return (
    <View style={styles.topContainer}>
      <View style={styles.barContainer}>
        <TouchableOpacity style={styles.iconContainer}>
          <Icon
            name="md-menu"
            size={Metrics.icons.medium}
            color={Colors.white}
          />
        </TouchableOpacity>
        <Text style={styles.titleLogoText}>{title !== 'title' && title}</Text>
        <View style={styles.iconContainer}>
          <Icon
            name="md-menu"
            size={Metrics.icons.small}
            color={Colors.transparent}
          />
        </View>
      </View>
    </View>
  );
}
