import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import ContactInfo, { ContactInfoComplete } from './ContactInfo';

// Styles
import styles from './Styles/ScreenStyles';
import { RENTAL_PROGRAM_CODE } from '../Config/ApplicationConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { checkRentalProgram } from '../Redux/ApplicationRedux';

const BASE = DATA_STRUCTURE.coApplicant;

export const CoapplicantInformationScreenComplete = (
  application,
  documents,
) => {
  return ContactInfoComplete(application, documents, BASE);
};

class CoapplicantInformationScreen extends Component {
  onComplete = (data) => {
    const { history, application } = this.props;

    const isRentalProgram = checkRentalProgram(application);
    if (isRentalProgram) {
      history.push('/DamagedAddressScreen');
    } else {
      history.push('/CoapplicantIncomeScreen');
    }
  };

  render() {
    return (
      <View style={styles.mainContainer}>
        <ContactInfo base={BASE} onComplete={this.onComplete} />
      </View>
    );
  }
}

const mapStateToProps = ({ persist: { application } }) => ({
  application,
});

export default connect(mapStateToProps)(CoapplicantInformationScreen);
