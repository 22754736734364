import {
  BrowserRouter as MyRouter,
  Switch as MySwitch,
  Route as MyRoute,
  Link as MyLink,
} from 'react-router-dom';

export const Router = MyRouter;
export const Route = MyRoute;
export const Switch = MySwitch;
export const Link = MyLink;
