import { Logs } from 'expo';
import { Text, Platform } from 'react-native';
// import ReactGA from 'react-ga';
// import firebase from "firebase/app";

import isDev from '../Lib/isDev';
import DebugConfig from './DebugConfig';
import AppConfig from './AppConfig';
import { GOOGLE_ANALYTIC_CODE } from './ApplicationConfig';
import FIREBASE_CONFIG from './FirebaseConfig';

// Logs.enableExpoCliLogging();

// Allow/disallow font-scaling in app
if (Platform.OS !== 'web') {
  // Text.defaultProps.allowFontScaling = AppConfig.allowTextFontScaling;

  if (isDev()) {
    // If ReactNative's yellow box warnings are too much, it is possible to turn
    // it off, but the healthier approach is to fix the warnings.  =)
    // console.disableYellowBox = !DebugConfig.yellowBox;
  }
  // } else if (GOOGLE_ANALYTIC_CODE) {
  //   ReactGA.initialize(GOOGLE_ANALYTIC_CODE);
} else {
  // Initialize Firebase
  // firebase.initializeApp(FIREBASE_CONFIG);
}
