import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';

import { View } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import { Grid, Row, Col } from '../Components/Grid';
import { isRequired, getPersonId, hasDocument } from '../Lib/Utils';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';

// Styles
import { Fonts, Metrics } from '../Themes';
import styles from './Styles/ScreenStyles';

import {
  YES_OPTION,
  NO_OPTION,
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE,
} from '../Config/ApplicationConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from '../Components/Footer';
import { getDocumentType } from '../Redux/DocumentRedux';

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE_APPLICANT = DATA_STRUCTURE.applicant;
const BASE = 'ownPropertyCode';

const PROOF_OF_OWNERSHIP_DOCUMENT_TYPE = 'proofOfOwnership';

export const OwnHomeScreenComplete = (application, documents) => {
  const applicantPersonId = getPersonId(application, BASE_APPLICANT);

  const documentType = getDocumentType(
    application,
    PROOF_OF_OWNERSHIP_DOCUMENT_TYPE,
  );

  // Did the user answer yes?
  if (R.path([BASE], application) === YES_OPTION) {
    return hasDocument(documents, applicantPersonId, documentType.code);
  }
  return R.pathOr('N/A', [BASE], application) !== 'N/A';
};

class OwnHomeScreen extends Component {
  state = {
    errorText: null,
  };

  updatingApplication = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
    } = nextProps;

    const isRentalProgram = checkRentalProgram(application);

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      console.log('returning', BASE, application[BASE]);
      if (errorText) {
        this.setState({ errorText });
      } else if (application[BASE] === YES_OPTION) {
        history.push('/OwnHomeProofScreen');
      } else if (application[BASE] === NO_OPTION) {
        if (isRentalProgram) {
          // Skip Primary Residence
          history.push('/FloodWaterEnterHomeScreen');
        } else {
          history.push('/PrimaryResidenceScreen');
        }
      } else {
        this.setState({ errorText: 'Error saving application' });
      }
    }
  }

  render() {
    const {
      initilized,
      submitForm,
      application,
      navigation,
      isValid,
      fetching,
    } = this.props;

    const isMobileHome =
      R.path([BASE_DAMAGED_ADDRESS, 'propertyTypeCode'], application) ===
      MOBILE_HOME_CODE;

    const disabled = false;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id={
                    isMobileHome
                      ? 'DamagedAddress.OwnMobileHomeTitle'
                      : 'DamagedAddress.OwnPropertyTitle'
                  }
                  style={Fonts.style.title}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={BASE}
                  labelId={
                    isMobileHome
                      ? 'DamagedAddress.OwnMobileHome'
                      : 'DamagedAddress.OwnProperty'
                  }
                  optionsKey="yes_no"
                  validate={isRequired}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  disabled={fetching}
                  onPress={submitForm}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

OwnHomeScreen = withFormik({
  mapPropsToValues: (props) => {
    const { application } = props;

    return application;
  },
  isInitialValid: (props) => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication } = props;
    // console.log('updatingApplication', values);
    updateApplication(values);
    setStatus('updatingApplication');
  },
})(OwnHomeScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error },
  persist: { options, application },
}) => ({
  options,
  initilized,
  fetching,
  errorText: error,
  application,
  initialValues: application,
});

const mapDispatchToProps = (dispatch) => ({
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnHomeScreen);
