import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Platform,
  Image,
  ImageBackground,
  TouchableOpacity,
  View,
  Text,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect, useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Ionicons as Icon, FontAwesome as FAIcon } from '@expo/vector-icons';

import { Controller, useFormContext, useForm } from 'react-hook-form';

import ErrorMessage from './ErrorMessage';

import { Grid, Row, Col } from './Grid';

import { isRequired, getPersonId } from '../Lib/Utils';

import DocumentActions, {
  getDocumentURL,
  DocumentTypes,
  getDocumentIcon,
} from '../Redux/DocumentRedux';

import FormattedMessage from './FormattedMessage';
import FormGroupRadio from './FormGroupRadio';
import FullButton from './FullButton';
import CaptureUploadDocuments from './CaptureUploadDocuments';
import LoadingScreen from '../Containers/LoadingScreen';

// Styles
import { Fonts, Colors, Metrics } from '../Themes';
import HookRadioInput from './HookRadioInput';
import { uploadDocumentsAsync } from '../Sagas/DocumentThunk';

export default function HookSelectUploadDocumentType(props) {
  // static propTypes = {
  //   onComplete: PropTypes.func.isRequired,
  //   scrollToTop: PropTypes.func,
  //   documentType: PropTypes.string.isRequired,
  //   base: PropTypes.string.isRequired,
  //   index: PropTypes.number,
  //   filterKeywordValues: PropTypes.array,
  //   buttonLabelId: PropTypes.string,
  //   labelId: PropTypes.string,
  //   hideButton: PropTypes.bool,
  //   hideDocTypes: PropTypes.bool,
  //   defaultDocuments: PropTypes.array,
  //   defaultKeywordValue: PropTypes.string,
  //   multiple: PropTypes.bool,
  //   horiz: PropTypes.bool,
  // };

  // static defaultProps = {
  //   scrollToTop: () => {},
  //   index: undefined,
  //   filterKeywordValues: [],
  //   buttonLabelId: 'form.continueNextStep',
  //   labelId: '',
  //   hideButton: false,
  //   hideDocTypes: false,
  //   defaultDocuments: [],
  //   defaultKeywordValue: null,
  //   multiple: true,
  //   horiz: false,
  // };

  const dispatch = useDispatch();

  const {
    scrollToTop = () => {},
    base,
    index,
    onComplete,
    documentType,
    buttonLabelId = 'form.continueNextStep',
    labelId = '',
    hideButton = false,
    multiple = true,
    horiz = false,
    hideDocTypes = false,
    defaultKeywordValue,
    filterKeywordValues = [],
    defaultDocuments = [],
  } = props;

  const {
    options,
    docTypes,
    application,
    account,
    tokenId,
    token,
  } = useSelector((state) => state.persist);
  const userId = account?.userId;

  const fieldDocument = `${documentType}_Documents`;
  const fieldKeyword = `${documentType}_Keyword`;

  const { documents, fetching, error, action } = useSelector(
    (state) => state.document,
  );

  const personId = getPersonId(application, base, index);

  // const renderInput = ({
  //   field: { name, value, onChange, onBlur },
  //   form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  //   deleteDocuments,
  //   documentType,
  //   multiple,
  //   horiz,
  //   disabled,
  // }) => (
  //   <CaptureUploadDocuments
  //     documentType={documentType}
  //     documents={value}
  //     setDocuments={(e) => {
  //       // console.log('setDocuments', name, JSON.stringify(e));
  //       // onChange(JSON.stringify(e));
  //       setFieldValue(name, e);
  //     }}
  //     deleteDocuments={deleteDocuments}
  //     disabled={disabled}
  //     multiple={multiple}
  //     horiz={horiz}
  //   />
  // );

  const [busy, setBusy] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { control, errors, watch, setValue, reset } = useFormContext();
  const existingDocuments = watch(fieldDocument);
  const existingKeyword = watch(fieldKeyword);

  // state = {
  //   errorText: '',
  // };

  // formInitilized = false;

  // loadedDocuments = false;

  // loadingDocuments = false;

  // loadingIdentityProofDocuments = false;

  // uploadingDocuments = false;

  // deletingDocuments = false;

  // componentDidMount() {
  //   // this.loadingDocuments = true;
  //   // this.props.loadDocuments(this.props.documentType);
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const {
  //     application,
  //     base,
  //     index,
  //     defaultDocuments,
  //     defaultKeywordValue,
  //     documentType,
  //     setFieldValue,
  //     fetching,
  //     errorText,
  //     resetForm,
  //     isSubmitting,
  //     setSubmitting,
  //     scrollToTop,
  //     documents,
  //     status,
  //     setStatus,
  //   } = nextProps;

  //   if (isSubmitting && status === 'uploadingDocuments' && !fetching) {
  //     scrollToTop();
  //     setSubmitting(false);
  //     setStatus('');
  //     if (errorText) {
  //       console.log('componentWillReceiveProps error', errorText);
  //       this.setState({ errorText });
  //     } else {
  //       console.log('componentWillReceiveProps success');
  //       resetForm();
  //     }
  //   }

  // }

  const handleDeleteFiles = async (keywordValueCode) => {
    console.log('deleteDocuments', personId, documentType, keywordValueCode);
    await dispatch(
      DocumentActions.DocumentDelete(personId, documentType, keywordValueCode),
    );
  };

  const handleReset = () => {
    console.log('handleReset resetForm');
    reset();
    // this.props.setFieldValue('Documents', []);
    // this.props.setFieldValue('KeywordValue', null);
  };

  const renderDocuments = () => {
    const filteredDocuments = {};
    const personId = getPersonId(application, base, index);

    // Filter Loaded Documents
    if (documents) {
      documents.forEach((ele) => {
        if (
          ele &&
          ele.documentType === documentType &&
          ele.personId === personId &&
          !filterKeywordValues.includes(ele.keywordValue)
        ) {
          if (!filteredDocuments[ele.keywordValue]) {
            filteredDocuments[ele.keywordValue] = {
              fileName: ele.fileName,
              keywordValue: ele.keywordValue,
              fileDownloadURL: ele.fileDownloadURL,
              count: 1,
            };
          } else {
            filteredDocuments[ele.keywordValue].count++;
          }
        }
      });
    }

    const rows = Object.keys(filteredDocuments).map((key, idx) => {
      const ele = filteredDocuments[key];

      const plusCount = ele.count - 1;
      const plusText = ele.count > 1 && (
        <View>
          <Text
            style={{
              ...Fonts.style.normal,
              // position: 'absolute',
              fontFamily: Fonts.type.bold,
              color: Colors.white,
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: Metrics.baseMargin,
            }}
          >
            + {plusCount}
          </Text>
        </View>
      );

      const photo = getDocumentURL(ele.fileDownloadURL, userId, tokenId, token);
      const icon = getDocumentIcon(ele.fileName, userId, tokenId, token);

      const value = R.path([documentType, ele.keywordValue, 'value'], docTypes);
      const keywordValue = R.path(
        [documentType, ele.keywordValue, 'keywordValue'],
        docTypes,
      );
      console.log('HookSelectUploadDocumentType - photo', photo);
      return (
        <Row
          key={idx}
          style={{
            borderWidth: 1,
            padding: Metrics.baseMargin,
            marginHorizontal: 0,
            borderColor: Colors.lightGrey,
            marginBottom: Metrics.baseMargin,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 90,
            }}
          >
            {icon !== 'file-image-o' ? (
              <View
                style={{
                  width: 90,
                  height: 90,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FAIcon name={icon} size={80} />
                <View style={{ position: 'absolute' }}>{plusText}</View>
              </View>
            ) : (
              <ImageBackground
                source={{ uri: photo }}
                style={{
                  // position: 'absolute',
                  width: 90,
                  height: 90,
                  // resizeMode: 'contain',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {plusText}
              </ImageBackground>
            )}
          </Col>
          <Col
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Text numberOfLines={2} style={Fonts.style.normal}>
              {value || keywordValue}
            </Text>
          </Col>
          <Col
            xs={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TouchableOpacity
              style={{
                // display: 'inline-block',
                paddingTop: Metrics.baseMargin,
                paddingBottom: Metrics.baseMargin,
                paddingLeft:
                  Platform.OS === 'web'
                    ? Metrics.doubleBaseMargin
                    : Metrics.baseMargin,
                paddingRight:
                  Platform.OS === 'web'
                    ? Metrics.doubleBaseMargin
                    : Metrics.baseMargin,
                marginRight: Metrics.baseMargin,
                borderRadius: 5,
                backgroundColor: '#aaa',
              }}
              onPress={() => handleDeleteFiles(key)}
            >
              <Icon name="md-trash" size={20} />
            </TouchableOpacity>
          </Col>
        </Row>
      );
      // }
    });

    return (
      rows && (
        <View>
          <Row style={{ padding: Metrics.baseMargin, marginHorizontal: 0 }}>
            <Col
              xs={3}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <FormattedMessage
                id="Image"
                style={[Fonts.style.normal, { fontWeight: 'bold' }]}
              />
            </Col>
            <Col
              span={{ xs: 9 }}
              style={{ justifyContent: 'center', alignItems: 'flex-start' }}
            >
              <FormattedMessage
                id="Type"
                style={[Fonts.style.normal, { fontWeight: 'bold' }]}
              />
            </Col>
          </Row>
          {rows}
        </View>
      )
    );
  };

  const onSubmit = async () => {
    console.log('onSubmit -> existingDocuments', existingDocuments);
    console.log('onSubmit -> existingKeyword', existingKeyword);

    setBusy(true);

    const results = await dispatch(
      uploadDocumentsAsync({
        personId,
        documents: existingDocuments,
        documentTypeCode: documentType,
        keywordValueCode: existingKeyword || '',
      }),
    );

    setValue(fieldDocument, []);
    setValue(fieldKeyword, '');

    scrollToTop();

    setBusy(false);
  };
  // console.log(JSON.stringify(values, null, 2));
  // console.log(JSON.stringify(docTypes[documentType], null, 2));
  // console.log('values', JSON.stringify(values, null, 2));

  const filteredOptions = {};

  Object.keys(docTypes[documentType] || {}).forEach((key) => {
    if (!filterKeywordValues.includes(key)) {
      filteredOptions[key] = docTypes[documentType][key];
    }
  });

  // console.log('HookSelectUploadDocumentType filteredOptions', filteredOptions);
  const hasDocument = existingDocuments && existingDocuments.length > 0;
  if (hasDocument) {
    console.log(existingDocuments);
  }

  const isValid = hasDocument && existingKeyword;

  return (
    <View>
      {renderDocuments()}
      <Row>
        <Col sm={12}>
          <ErrorMessage errorText={errorText} />

          <Controller
            name={fieldDocument}
            control={control}
            defaultValue={[]}
            // rules={rules}
            render={({ value, onChange }) => (
              <CaptureUploadDocuments
                documentType={documentType}
                documents={value}
                setDocuments={(e) => {
                  // console.log('setDocuments', name, JSON.stringify(e));
                  // onChange(JSON.stringify(e));
                  onChange(e);
                }}
                deleteDocuments={handleReset}
                multiple={multiple}
                horiz={horiz}
              />
            )}
          />
        </Col>
      </Row>
      {(hasDocument || !hideDocTypes) &&
        Object.keys(filteredOptions).length > 0 && (
          <Row>
            <Col sm={12}>
              <HookRadioInput
                control={control}
                field={fieldKeyword}
                labelId={labelId}
                disabled={!hasDocument}
                options={filteredOptions}
              />
            </Col>
          </Row>
        )}

      <Row>
        <Col sm={12}>
          {hasDocument && (
            <FullButton
              text={I18n.t('buttons.SaveDocument')}
              onPress={() => {
                onSubmit();
              }}
              disabled={!isValid || busy}
            />
          )}
          {!hasDocument && !hideButton && (
            <FullButton text={I18n.t(buttonLabelId)} onPress={onComplete} />
          )}

          <View style={{ marginTop: Metrics.doubleBaseMargin }} />
        </Col>
      </Row>
    </View>
  );
}

// HookSelectUploadDocumentType = withFormik({
//   mapPropsToValues: (props) => {
//     const {
//       application,
//       base,
//       index,
//       documents,
//       documentType,
//       defaultDocuments,
//       defaultKeywordValue,
//     } = props;

//     // return {
//     //   Documents: defaultDocuments,
//     //   KeywordValue: defaultKeywordValue
//     // };

//     const personId = getPersonId(application, base, index);
//     // console.log('mapPropsToValues', documents);
//     // Search Documents for default
//     let found = false;
//     if (defaultKeywordValue) {
//       documents.forEach((ele) => {
//         if (
//           ele &&
//           documentType === ele.documentType &&
//           ele.personId === personId &&
//           ele.keywordValue === defaultKeywordValue
//         ) {
//           found = true;
//         }
//       });
//     }

//     // If found, don't set default documents.
//     if (found) {
//       console.log('found', found);
//       return {
//         Documents: [],
//         KeywordValue: null,
//       };
//     }
//     return {
//       Documents: defaultDocuments,
//       KeywordValue: defaultKeywordValue,
//     };
//   },
//   isInitialValid: (props) =>
//     props.defaultKeywordValue &&
//     props.defaultDocuments &&
//     props.defaultDocuments.length > 0,
//   handleSubmit: (values, { setStatus, props }) => {
//     // props.updateApplication(values);
//     const {
//       application,
//       uploadDocuments,
//       base,
//       index,
//       setFieldValue,
//       documentType,
//     } = props;

//     const personId = getPersonId(application, base, index);

//     console.log('handleSubmit', application, base, index, personId);

//     const { Documents, KeywordValue } = values;

//     // this.uploadingDocuments = true;
//     // console.log(
//     //   'HookSelectUploadDocumentType handleSubmit uploadDocuments',
//     //   personId,
//     //   Documents,
//     //   documentType,
//     //   KeywordValue
//     // );

//     uploadDocuments(personId, Documents, documentType, KeywordValue || '');
//     setStatus('uploadingDocuments');
//   },
// })(HookSelectUploadDocumentType);

// const mapStateToProps = ({
//   app: { initilized },
//   persist: { options, docTypes, application, account, tokenId, token },
//   document: { documents, fetching, error, action },
//   // form
// }) => ({
//   userId: R.path(['userId'], account),
//   tokenId,
//   token,
//   options,
//   docTypes,
//   documents,
//   action,
//   fetching,
//   errorText: error,
//   initilized,
//   application,
//   // initialValues: application
//   // currentForm: form.OtherIncome
// });

// const mapDispatchToProps = (dispatch) => ({
//   // saveApplication: (application) => dispatch(ApplicationActions.ApplicationSuccess(application)),
//   loadDocuments: (docType) =>
//     dispatch(DocumentActions.DocumentLoadDocuments(docType)),
//   uploadDocuments: (
//     personId,
//     documents,
//     documentTypeCode,
//     keywordValueCode,
//     documentId,
//   ) =>
//     dispatch(
//       DocumentActions.DocumentUpload(
//         personId,
//         documents,
//         documentTypeCode,
//         keywordValueCode,
//         documentId,
//       ),
//     ),
//   deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
//     dispatch(
//       DocumentActions.DocumentDelete(
//         personId,
//         documentTypeCode,
//         keywordValueCode,
//       ),
//     ),
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(HookSelectUploadDocumentType);
