import React, { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import { PRODUCTION } from '../Config/ApplicationConfig';

export default ({ ref, testID, ...rest }) => {
  let myRef;
  useEffect(() => {
    if (myRef && testID && !PRODUCTION) {
      myRef.setNativeProps({
        'data-test': testID,
      });
    }
  }, [testID, myRef]);

  return (
    <TouchableOpacity
      ref={(thisRef) => {
        myRef = thisRef;
        if (ref) ref(thisRef);
      }}
      {...rest}
    />
  );
};
