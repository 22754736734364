import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { composeWithDevTools } from 'redux-devtools-extension';

import ReduxPersist from '../Config/ReduxPersist';
import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';
import { persistReducer, persistStore } from 'redux-persist';

import api from '../Services/Api';

// creates the store
export default (history, rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Analytics Middleware ------------- */
  // middleware.push(ScreenTracking)

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware({});
  middleware.push(sagaMiddleware);

  /* ------------- Routing Middleware ------------- */

  middleware.push(routerMiddleware(history));

  /* ------------- Thunk Middleware for react-redux-18n ------------- */

  middleware.push(thunk.withExtraArgument(api));

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  const persistedReducer = persistReducer(
    ReduxPersist.storeConfig,
    rootReducer,
  );

  const store = createStore(
    persistedReducer,
    composeWithDevTools(compose(...enhancers)),
    // compose(...enhancers)
  );

  // configure persistStore and check reducer version number
  // if (ReduxPersist.active) {
  //   Rehydration.updateReducers(store);
  // }

  // start up react-redux-18n sync
  syncTranslationWithStore(store);
  store.dispatch(setLocale(DEFAULT_LANGUAGE));

  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga);
  let persistor = persistStore(store);

  return { store, persistor, sagasManager, sagaMiddleware };
};
