import { Platform, Image } from 'react-native';
import { call, put, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { v1 as uuidv1 } from 'uuid';
// import RNFS from 'react-native-fs';
// import RNFetchBlob from "rn-fetch-blob";
// import ImageResizer from 'react-native-image-resizer';
// import { Upload } from "react-native-tus-client";
// import base64 from 'base-64';
import { Asset } from 'expo-asset';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';

import DocumentActions, {
  DocumentTypes,
  getDocumentURL,
} from '../Redux/DocumentRedux';

import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';
import {
  IMAGE_RESIZE_MAX,
  IMAGE_RESIZE_QUALITY,
} from '../Config/ApplicationConfig';
import { isNumeric } from '../Lib/Utils';

export const getDocumentsAsync = ({ docType }) => async (
  dispatch,
  getState,
  api,
) => {
  const {
    application,
    account,
    token,
    tokenId,
    offlineDocuments,
  } = getState().persist;

  const existingDocuments = getState().document.documents;

  const applicationId = application?.id;
  const userId = account?.userId;

  console.log('getDocuments applicationId', applicationId);

  if (applicationId) {
    if (!isNumeric(applicationId)) {
      dispatch(
        DocumentActions.DocumentSuccess(offlineDocuments[applicationId]),
      );
    } else {
      let results = '';
      if (applicationId > 0) {
        try {
          // console.log('api.getDocuments', userId, tokenId, token, applicationId, docType)
          results = await api.getDocuments(
            userId,
            tokenId,
            token,
            applicationId,
            null,
            // docType
          );
        } catch (error) {
          if (R.path(['data', 'payload'], error)) {
            results = error;
          }
        }
      } else {
        results = { data: { error: [{ code: 200 }] } };
      }
      console.log('getDocuments', docType, results);

      // Fix for No Documents Error
      if (R.path(['data', 'error', 0, 'code'], results) === '200') {
        await dispatch(DocumentActions.DocumentSuccess([]));
        return true;
      } else if (R.path(['data', 'payload', 0, 'documents'], results)) {
        await dispatch(
          DocumentActions.DocumentSuccess(results.data.payload[0].documents),
        );
        return true;
      } else if (R.path(['data', 'error', 0, 'message'], results)) {
        await dispatch(
          DocumentActions.DocumentFailure(results.data.error[0].message),
        );
        return false;
      } else {
        await dispatch(DocumentActions.DocumentFailure('Unknown Error'));
        return false;
      }
    }
  }
};

export const uploadSignatureAsync = ({
  personId,
  signature,
  documentTypeCode,
  keywordValueCode,
}) => async (dispatch, getState, api) => {
  const image = Asset.fromURI(signature.localUri || signature.uri);
  console.log('image', image);

  const manipResult = await ImageManipulator.manipulateAsync(
    image.localUri || image.uri,
    [{ rotate: -90 }],
    { compress: 1, format: ImageManipulator.SaveFormat.PNG },
  );

  console.log('manipResult', manipResult);

  // const filePathBase = Platform.OS === 'ios'
  //   ? RNFS.TemporaryDirectoryPath
  //   : RNFS.DocumentDirectoryPath
  // TODO: FIX
  console.log('uploadSignature personId', personId);
  // const filePathBase = RNFS.DocumentDirectoryPath;
  // const filename = `${filePathBase}/${uuidv1()}.png`;
  // // Write Encoded Contents to file for Cross Platform Compatibility
  // console.log('writing signature', filename, signature);
  // yield call(RNFS.writeFile, filename, signature.encoded, 'base64');
  // // const file = yield call(RNFS.readFile, filename, 'base64')
  // // console.log(file)
  const documents = [{ uri: manipResult.localUri || manipResult.uri }];

  console.log('starting uploadDocumentsAsync');
  const uploadResults = await dispatch(
    uploadDocumentsAsync({
      personId,
      documents,
      documentTypeCode,
      keywordValueCode,
    }),
  );
  console.log('ending uploadDocumentsAsync: ', uploadResults);
  return uploadResults;
};

export const uploadDocumentsAsync = ({
  personId,
  documents,
  documentTypeCode,
  keywordValueCode,
  description,
}) => async (dispatch, getState, api) => {
  const {
    application,
    account,
    token,
    tokenId,
    offlineDocuments,
  } = getState().persist;

  const applicationId = application?.id;
  const userId = account?.userId;
  console.log('uploadDocuments applicationId', applicationId);
  console.log('userId', userId);
  console.log('personId', personId);
  console.log('documents', documents);
  console.log('description', description);
  console.log('Platform.OS', Platform.OS);

  // Resize/Recompress Images
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    for (let i = 0; i < documents.length; i++) {
      // height:4032
      // iOS: uri:"file:///var/mobile/Containers/Data/Application/A8FAFC31-D8F2-4D6B-8EBA-D75E2051F313/Library/Caches/Camera/F1A7AFCC-EE43-4C23-A29F-17BC6547D796.jpg"
      // width:2268
      if (
        documents[i].height > IMAGE_RESIZE_MAX ||
        documents[i].width > IMAGE_RESIZE_MAX
      ) {
        const sourceUri = documents[i].localUri || documents[i].uri;
        console.log('documents[i]', documents[i]);
        const originalInfo = await FileSystem.getInfoAsync(sourceUri, {
          size: true,
        });
        console.log('originalInfo', originalInfo);

        const resizeParams =
          documents[i].width > documents[i].height
            ? {
                width: IMAGE_RESIZE_MAX,
              }
            : { height: IMAGE_RESIZE_MAX };

        const resizedImage = await ImageManipulator.manipulateAsync(
          sourceUri,
          [{ resize: resizeParams }],
          { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG },
        );
        const resizedUri = resizedImage.localUri || resizedImage.uri;

        const resizedInfo = await FileSystem.getInfoAsync(resizedUri, {
          size: true,
        });
        console.log('resizedInfo', resizedInfo);

        if (resizedInfo.size > originalInfo.size) {
          console.log('File got bigger.  Delete new file.');
          // File got bigger.  Delete new file.
          await FileSystem.deleteAsync(resizedUri);
        } else {
          console.log(
            'File is smaller.  Delete original file and update document.',
          );
          // File is smaller.  Delete original file and update document.
          await FileSystem.deleteAsync(sourceUri);

          documents[i].width = resizedImage.width;
          documents[i].height = resizedImage.height;
          documents[i].uri = resizedImage.uri;
        }

        // TODO: FIX
        // const results = yield call(RNFetchBlob.fs.stat, sourcePath);
        // const originalSize = results.size;
        // console.log("TCL: originalSize", originalSize);

        // const resizedResults = yield call(
        //   ImageResizer.createResizedImage,
        //   sourcePath, // imageUri
        //   IMAGE_RESIZE_MAX, // documents[i].width, // newWidth
        //   IMAGE_RESIZE_MAX, // documents[i].height, // newHeight
        //   "JPEG", // or 'PNG' // compressFormat
        //   IMAGE_RESIZE_QUALITY, // quality - ignored for PNG
        //   0, // rotation
        //   RNFS.DocumentDirectoryPath // outputPath
        // );
        // const newSize = resizedResults;
        // console.log("TCL: resizedResults", resizedResults);

        // if (newSize > originalSize) {
        //   console.log("File got bigger.  Delete new file.");
        //   // File got bigger.  Delete new file.
        //   yield call(RNFS.unlink, resizedResults.path);
        // } else {
        //   console.log(
        //     "File is smaller.  Delete original file and update document."
        //   );
        //   // File is smaller.  Delete original file and update document.
        //   const newFileStats = yield call(getImageSize, resizedResults.uri);

        //   yield call(RNFS.unlink, sourcePath);
        //   documents[i].width = newFileStats.width;
        //   documents[i].height = newFileStats.height;
        //   documents[i].uri = resizedResults.uri;
      }
    }
  }

  if (!isNumeric(applicationId)) {
    // Offline Documents
    let newOfflineDocuments = JSON.parse(JSON.stringify(offlineDocuments));
    if (JSON.stringify(offlineDocuments) === '[]') {
      console.log('fixing object');
      newOfflineDocuments = {};
    }

    // Set Default offlineDocuments
    if (!newOfflineDocuments[applicationId]) {
      newOfflineDocuments[applicationId] = [];
    }
    console.log(
      'uploadDocuments saga',
      documentTypeCode,
      keywordValueCode,
      documents,
    );

    documents.forEach((ele, idx) => {
      console.log('ele', ele);
      const pageOrder = idx + 1;
      const offlineFilename = ele.uri.replace(/^.*[\\\/]/, '');
      newOfflineDocuments[applicationId].push({
        id: '-1',
        personId,
        documentType: documentTypeCode,
        keywordValue: keywordValueCode,
        pageOrder,
        description,
        documentPageId: '-1',
        fileName: offlineFilename,
        file: ele,
        fileDownloadURL: ele.uri,
      });
    });

    dispatch(PersistActions.PersistSetOfflineDocuments(newOfflineDocuments));
    dispatch(
      DocumentActions.DocumentSuccess(newOfflineDocuments[applicationId]),
    );
    return newOfflineDocuments[applicationId];
  } else {
    let results = '';
    try {
      console.log(
        'uploadDocuments',
        documentTypeCode,
        keywordValueCode,
        documents,
      );

      const data = new FormData();

      data.append('PersonID', personId); // Applicant?
      data.append('DocumentTypeCode', documentTypeCode); // Proof of Primary Residence
      // data.append('DocumentID', documentId || '-1')
      data.append('KeywordValueCode', keywordValueCode); // Driver's Lience
      data.append('KeywordSortOrder', '1');

      if (description) data.append('Description', description);

      // TODO:FIX
      const filePathBase = ''; // RNFS.DocumentDirectoryPath;

      // TODO: FIX
      const allDocuments = getState().document.documents;
      // Find existing documentId if exists.  We are assuming that Documents have already been fetched.
      let documentId = '-1';
      let pageOrder = 1;
      if (allDocuments) {
        allDocuments.forEach((file) => {
          if (
            file &&
            file.keywordValue === keywordValueCode &&
            file.documentType === documentTypeCode &&
            file.personId === personId
          ) {
            documentId = file.id || '-1';
            pageOrder++;
          }
        });
      }

      for (let i = 0; i < documents.length; i++) {
        const ele = documents[i];
        let filename;

        data.append('PageOrder', (i + pageOrder).toString());
        if (Platform.OS === 'web') {
          console.log('WEB: remote url:', ele);
          data.append('file', ele);
        } else {
          // Standardize Filenames between Android and iOS.
          filename = ele.uri.replace(/^.*[\\\/]/, '');

          // If photo is remote, then download and re-upload it
          if (ele.uri.indexOf('http') === 0) {
            filename = `${uuidv1()}.jpg`;
            const sourceFile = getDocumentURL(ele.uri, userId, tokenId, token);
            const destFile = FileSystem.documentDirectory + filename;
            console.log('downloading', sourceFile, destFile);

            const res = await FileSystem.downloadAsync(sourceFile, destFile);

            // const res = yield call(
            //   downloadFileFromUrlToPath,
            //   sourceFile,
            //   destFile
            // );
            console.log('done downloading', res);
            ele.uri = destFile;
          }

          const ext = filename.split('.').pop().toLowerCase();
          const mimeType =
            ext === 'jpg'
              ? 'image/jpeg'
              : ext === 'jpeg'
              ? 'image/jpeg'
              : ext === 'png'
              ? 'image/png'
              : null;

          data.append('file', {
            uri:
              Platform.OS === 'android' && ele.uri.indexOf('/') === 0
                ? `file://${ele.uri}`
                : ele.uri,
            type: mimeType,
            name: filename,
          });
        }
      }

      // console.log(
      //   'api.uploadDocuments',
      //   userId,
      //   tokenId,
      //   token,
      //   applicationId,
      //   documentId,
      //   data
      //   // JSON.stringify(data, null, 2)
      // );

      console.log('api.uploadDocuments userId', userId);
      console.log('tokenId', tokenId);
      console.log('token', token);
      console.log('applicationId', applicationId);
      console.log('documentId', documentId);
      if (Platform.OS === 'web') {
        console.log('web FormData:');
        // Display the key/value pairs
        for (const pair of data.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`);
        }
      } else {
        console.log('mobile FormData', data);
      }

      results = await api.uploadDocuments(
        userId,
        tokenId,
        token,
        applicationId,
        documentId,
        data,
      );
      // results = yield call(
      //   api.uploadDocuments,
      //   userId,
      //   tokenId,
      //   token,
      //   applicationId,
      //   documentId,
      //   data
      // );
      console.log('upload results', results);
    } catch (error) {
      console.log('error', error);
      if (R.path(['data', 'payload', 0, 'documents', 0, 'id'], error)) {
        results = error;
      }
    }

    if (R.path(['data', 'payload', 0, 'documents', 0, 'id'], results)) {
      // yield put(DocumentActions.DocumentSuccess(results.data.payload[0]))
      results = await dispatch(getDocumentsAsync({ docType: null }));
      console.log('results getDocumentsAsync', results);

      return results;
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      console.log('error', results.data.error[0].message);
      dispatch(DocumentActions.DocumentFailure(results.data.error[0].message));
      // yield put(DocumentActions.DocumentFailure(results.data.error[0].message));
      return { error: results.data.error[0].message };
    } else {
      console.log('error', 'unknown');
      dispatch(DocumentActions.DocumentFailure('Unknown Error'));
      // yield put(DocumentActions.DocumentFailure("Unknown Error"));
      return { error: 'Unknown Error' };
    }

    // // yield call(getDocuments, api, { docType: documentTypeCode });
    // yield call(getDocuments, api, { docType: null });
  }
};

export const deleteDocumentsAsync = ({
  personId,
  documentTypeCode,
  keywordValueCode,
}) => async (dispatch, getState, api) => {
  const {
    application,
    account,
    token,
    tokenId,
    offlineDocuments,
  } = getState().persist;

  const applicationId = application?.id;
  const userId = account?.userId;

  if (!isNumeric(applicationId)) {
    // Offline Documents
    const newOfflineDocuments =
      JSON.parse(JSON.stringify(offlineDocuments)) || {};

    if (!newOfflineDocuments[applicationId]) {
      newOfflineDocuments[applicationId] = [];
    }
    // Search Documents for Matching ApplicationId, PersonId and DocType
    const newDocs = newOfflineDocuments[applicationId].filter(
      (ele) =>
        !(ele.personId === personId && ele.documentType === documentTypeCode),
    );

    newOfflineDocuments[applicationId] = newDocs;

    // Filter Only DocType Documents
    // const filteredDocuments = (newOfflineDocuments[applicationId] || []).filter(ele => ele.documentType === documentTypeCode);

    dispatch(PersistActions.PersistSetOfflineDocuments(newOfflineDocuments));
    // dispatch(DocumentActions.DocumentSuccess(filteredDocuments));
    dispatch(
      DocumentActions.DocumentSuccess(newOfflineDocuments[applicationId] || []),
    );
  } else {
    let results = '';
    // Load Existing Documents
    try {
      console.log(
        'api.getDocuments',
        userId,
        tokenId,
        token,
        applicationId,
        documentTypeCode,
      );
      results = await api.getDocuments(
        userId,
        tokenId,
        token,
        applicationId,
        documentTypeCode,
      );
    } catch (error) {
      if (R.path(['data', 'payload'], error)) {
        results = error;
      }
    }

    let documents = [];
    // Fix for No Documents Error
    if (R.path(['data', 'error', 0, 'code'], results) === '200') {
      documents = [];
    } else if (R.path(['data', 'payload', 0, 'documents'], results)) {
      documents = results.data.payload[0].documents;
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      dispatch(DocumentActions.DocumentFailure(results.data.error[0].message));
    } else {
      dispatch(DocumentActions.DocumentFailure('Unknown Error'));
    }

    console.log('documents', documents);
    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      if (
        doc.personId === personId &&
        doc.documentType === documentTypeCode &&
        (keywordValueCode === '' || doc.keywordValue === keywordValueCode)
      ) {
        console.log(
          'api.deleteRecord',
          'Document',
          userId,
          tokenId,
          token,
          applicationId,
          doc.id,
        );
        results = await api.deleteRecord(
          'Document',
          userId,
          tokenId,
          token,
          applicationId,
          doc.id,
        );
        console.log(results);
      }
    }

    console.log('starting getDocumentsAsync');
    results = await dispatch(getDocumentsAsync({ documentTypeCode }));
    console.log('ending getDocumentsAsync: ', results);
    return results;
  }
};
