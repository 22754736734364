import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView, Text } from 'react-native';
import { Colors } from '../Themes';

// Source: https://stackoverflow.com/questions/47725607/react-native-safeareaview-background-color-how-to-assign-two-different-backgro

export default class SafeArea extends Component {
  static propTypes = {
    headerBackground: PropTypes.string,
    footerBackground: PropTypes.string,
  };

  static defaultProps = {
    headerBackground: Colors.notchDefaultHeaderBackground,
    footerBackground: Colors.notchDefaultFooterBackground,
  };

  render() {
    const { headerBackground, footerBackground, children } = this.props;
    return (
      <Fragment>
        <SafeAreaView style={{ flex: 0, backgroundColor: headerBackground }} />
        <SafeAreaView style={{ flex: 1, backgroundColor: footerBackground }}>
          {children}
        </SafeAreaView>
      </Fragment>
    );
  }
}
