import { StyleSheet } from 'react-native';
import { Metrics, Fonts, Colors } from '../../Themes';

export default StyleSheet.create({
  header: {
    backgroundColor: '#000000',
  },
  background: {
    backgroundColor: Colors.white,
    height: '100%',
  },
  logoContainer: {
    marginTop: Metrics.doubleBaseMargin,
    marginBottom: Metrics.baseMargin,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 172,
    height: 172,
    resizeMode: 'contain',
  },
  itemContainer: {
    padding: Metrics.baseMargin,
  },
  itemContainerActive: {
    padding: Metrics.baseMargin,
    backgroundColor: Colors.drawerActiveItemBackground,
  },
  itemText: {
    ...Fonts.style.menuItem,
  },
  itemTextActive: {
    ...Fonts.style.menuItem,
  },
  item: {},
  itemIndent: {
    paddingLeft: 30,
  },
  itemIndentText: {
    ...Fonts.style.menuItemIndent,
  },
  itemIndentTextBold: {
    ...Fonts.style.menuItemIndent,
    fontWeight: 'bold',
  },
});
