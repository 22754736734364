import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { Image, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

// import { Ionicons as Icon } from "@expo/vector-icons";
import { hasDocument, getPersonId } from '../Lib/Utils';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';
import SelectUploadDocumentType from '../Components/SelectUploadDocumentType';

import DocumentActions, {
  getDocumentURL,
  getDocumentType,
} from '../Redux/DocumentRedux';

// Styles
import { Fonts, Colors, Metrics } from '../Themes';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

import {
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE,
  YES_OPTION,
} from '../Config/ApplicationConfig';
import Footer from '../Components/Footer';

const DOCUMENT_TYPE = 'proofOfDisability';

// const PROOF_OF_MOBILE_HOME_OWNERSHIP_DOCUMENT_TYPE =
//   DOCUMENT_TYPES.proofOfMobileHomeOwnership.code;

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const COAPPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.householdMembers;
// const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;

const BASE = 'ownHomeProof';

export const householdMembersHaveDisability = (application) => {
  if (R.path([APPLICANT_BASE, 'disabilityCode'], application) === YES_OPTION)
    return true;
  if (R.path([COAPPLICANT_BASE, 'disabilityCode'], application) === YES_OPTION)
    return true;
  if (
    application[HOUSEHOLD_MEMBERS_BASE] &&
    Array.isArray(application[HOUSEHOLD_MEMBERS_BASE])
  ) {
    application[HOUSEHOLD_MEMBERS_BASE].forEach((member) => {
      if (member.disabilityCode === YES_OPTION) return true;
    });
  }
  return false;
};

export const DisabilityProofScreenComplete = (application, documents) => {
  if (!householdMembersHaveDisability(application)) return true;
  const applicantPersonId = getPersonId(application, APPLICANT_BASE);
  const documentType = getDocumentType(application, DOCUMENT_TYPE);
  return hasDocument(documents, applicantPersonId, documentType.code);
};
class DisabilityProofScreen extends Component {
  initilized = false;

  UNSAFE_componentWillMount() {
    const { application, history } = this.props;
    if (application && application.id) {
      this.handleCheckForDisability(application, history);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { application, history } = nextProps;
    if (application && application.id) {
      this.handleCheckForDisability(application, history);
    }
  }

  handleCheckForDisability(application, history) {
    this.initilized = true;

    if (!householdMembersHaveDisability(application))
      history.push('/DamagedAddressScreen');
  }

  onComplete = () => {
    const { application, history } = this.props;

    // const isRentalProgram = checkRentalProgram(application)

    history.push('/DamagedAddressScreen');
  };

  render() {
    const { application, documents, initilized } = this.props;

    // const isMobileHome =
    //   R.path([DAMAGED_ADDRESS_BASE, 'propertyType'], application) ===
    //   MOBILE_HOME_CODE;

    // const DOCUMENT_TYPE = isMobileHome
    //   ? PROOF_OF_MOBILE_HOME_OWNERSHIP_DOCUMENT_TYPE
    //   : PROOF_OF_OWNERSHIP_DOCUMENT_TYPE;

    // const hasDocument = R.path(
    //   [DAMAGED_ADDRESS_BASE, BASE, 'documents'],
    //   values
    // );
    const hasDocument = false;
    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    console.log(
      'TCL: DisabilityProofScreen -> render -> documentType',
      documentType,
    );

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DisabilityProof.title"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage
                  id="DisabilityProof.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <SelectUploadDocumentType
                  documentType={documentType.code}
                  scrollToTop={() => this._scroll.scrollTo({ x: 0, y: 0 })}
                  base={APPLICANT_BASE}
                  onComplete={this.onComplete}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, application, account, tokenId, token },
  document: { documents, fetching, error },
  form,
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application,
  initialValues: application,
});

const mapDispatchToProps = (dispatch) => ({
  saveApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId,
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId,
      ),
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisabilityProofScreen);
