import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

// Styles
import styles from './Styles/ScreenStyles';
import { Colors } from '../Themes';

import Footer from '../Components/Footer';

export default function LoadingScreen() {
  return (
    <View style={styles.mainContainer}>
      <View
        style={{
          backgroundColor: Colors.background,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator color={Colors.buttonBlueLight} size="large" />
      </View>
      <Footer />
    </View>
  );
}
