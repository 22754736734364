import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import ScanID from '../Components/ScanID';
import { getPersonId, hasDocument } from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import { getDocumentType } from '../Redux/DocumentRedux';

const BASE = DATA_STRUCTURE.coApplicant;
const DOCUMENT_TYPE = 'proofOfIdentification';

export const CoapplicantIdentificationScreenComplete = (
  application,
  documents,
) => {
  const applicantPersonId = getPersonId(application, BASE);
  const documentType = getDocumentType(application, DOCUMENT_TYPE);
  return hasDocument(documents, applicantPersonId, documentType.code);
};

class CoapplicantIdentificationScreen extends Component {
  onComplete = (data) => {
    const { history } = this.props;
    history.push('CoapplicantInformationScreen');
  };

  render() {
    return (
      <View style={styles.mainContainer}>
        <ScanID
          base={BASE}
          onComplete={this.onComplete}
          onCancel={this.onComplete}
        />
      </View>
    );
  }
}

const mapStateToProps = ({
  application: { application },
  app: { initilized },
}) => ({
  application,
  initilized,
});

export default connect(mapStateToProps)(CoapplicantIdentificationScreen);
