import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  View,
  Text,
  Image,
  Platform,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Ionicons as Icon, FontAwesome as FAIcon } from '@expo/vector-icons';

import { Grid, Row, Col } from './Grid';
import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, {
  getDocumentURL,
  getDocumentIcon,
} from '../Redux/DocumentRedux';
import FormattedMessage from './FormattedMessage';
import ErrorMessage from './ErrorMessage';
import LoadingScreen from '../Containers/LoadingScreen';

import FullButton from './FullButton';
import CaptureUploadDocuments from './CaptureUploadDocuments';
import { getPersonId } from '../Lib/Utils';
// Styles
import { Fonts, Colors, Metrics } from '../Themes';
import styles from '../Containers/Styles/ScreenStyles';

class UploadDocumentType extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    documentType: PropTypes.string.isRequired,
    documentKeywordValue: PropTypes.string,
    single: PropTypes.bool,
    horiz: PropTypes.bool,
  };

  static defaultProps = {
    documentKeywordValue: '',
    single: false,
    horiz: false,
  };

  state = {
    loading: true,
    uploading: false,
  };

  formInitilized = false;

  loadedDocuments = false;

  loadingDocuments = false;

  loadingIdentityProofDocuments = false;

  uploadingDocuments = false;

  deletingDocuments = false;

  componentDidMount() {
    // const { documentType } = this.props;
    // this.loadingDocuments = true;
    // this.props.loadDocuments(documentType);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isSubmitting,
      setSubmitting,
      data,
      errorText,
      fetching,
      initilized,
      onComplete,
      currentForm,
      // resetForm
    } = nextProps;

    // if (this.loadingDocuments && !fetching) {
    //   this.setState({ loading: false });
    //   if (errorText) {
    //     this.refs.scroll.scrollTo({ x: 0, y: 0 });
    //     this.setState({ errorText, uploading: false });
    //   } else {
    //     this.refs.scroll.scrollTo({ x: 0, y: 0 });
    //     this.setState({ uploading: false });
    //     // resetForm();
    //   }
    // }

    if (this.uploadingDocuments && !fetching) {
      this.uploadingDocuments = false;
      console.log('errorText', errorText);
      if (errorText) {
        this.setState({ errorText, uploadingDocuments: false });
      } else {
        this.setState({ uploadingDocuments: false });
        // resetForm();
      }
    }

    if (this.deletingDocuments && !fetching) {
      this.deletingDocuments = false;
      console.log('errorText', errorText);
      if (errorText) {
        this.setState({ errorText, deletingDocuments: false });
      } else {
        this.setState({ deletingDocuments: false });
        // resetForm();
      }
    }

    // if (isSubmitting && !fetching && errorText) {
    //   setSubmitting(false);
    //   this.setState({ errorText });
    //   // Scroll to top
    //   this._scroll.scrollTo({ x: 0, y: 0 });
    // } else if (isSubmitting && !fetching && data) {
    //   // setSubmitting(false);
    //   console.log('onComplete', data);
    //   onComplete(data);
    // }
  }

  handleUploadFiles = (images) => {
    // console.log(images);

    const {
      application,
      uploadDocuments,
      base,
      index,
      documentType,
      documentKeywordValue,
    } = this.props;

    const personId = getPersonId(application, base, index);
    // if (token) {

    this.setState({ uploadingDocuments: true });
    this.uploadingDocuments = true;
    console.log(
      'handleUploadFiles',
      application,
      base,
      index,
      personId,
      images,
      documentType,
      documentKeywordValue,
    );
    uploadDocuments(personId, images, documentType, documentKeywordValue);
  };

  handleDeleteFiles = () => {
    const {
      application,
      deleteDocuments,
      base,
      index,
      documentType,
      documentKeywordValue,
    } = this.props;

    const personId = getPersonId(application, base, index);

    this.setState({ deletingDocuments: true });
    this.deletingDocuments = true;
    console.log(
      'deleteDocuments',
      personId,
      documentType,
      documentKeywordValue,
    );
    deleteDocuments(personId, documentType, documentKeywordValue);
  };

  // onSubmit = values => {
  //   const { updateApplication } = this.props;

  //   this.savingApplication = true;
  //   updateApplication(values);
  // };

  renderDocuments(documents) {
    const {
      application,
      base,
      index,
      docTypes,
      tokenId,
      token,
      userId,
      single,
      documentType,
      documentKeywordValue,
    } = this.props;

    const personId = getPersonId(application, base, index);

    const filteredDocuments = {};
    if (documents) {
      documents.forEach((ele, idx) => {
        if (ele) {
          if (
            ele.documentType === documentType &&
            (!documentKeywordValue ||
              (documentKeywordValue &&
                ele.keywordValue === documentKeywordValue)) && // ele.keywordValue === documentKeywordValue &&
            ele.personId === personId
          ) {
            if (!filteredDocuments[ele.keywordValue]) {
              filteredDocuments[ele.keywordValue] = {
                fileName: ele.fileName,
                keywordValue: ele.keywordValue,
                fileDownloadURL: ele.fileDownloadURL,
                count: 1,
              };
            } else {
              filteredDocuments[ele.keywordValue].count++;
            }
          }
        }
      });
    }
    // console.log('filteredDocuments', documentType, filteredDocuments);

    if (single) {
    }

    const rows = Object.keys(filteredDocuments).map((key, idx) => {
      const ele = filteredDocuments[key];

      const plusCount = ele.count - 1;
      const plusText = ele.count > 1 && (
        <View>
          <Text
            style={{
              ...Fonts.style.normal,
              // position: 'absolute',
              fontFamily: Fonts.type.bold,
              color: Colors.white,
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: Metrics.baseMargin,
            }}
          >
            + {plusCount}
          </Text>
        </View>
      );

      const icon = getDocumentIcon(ele.fileName);
      const photo = getDocumentURL(ele.fileDownloadURL, userId, tokenId, token);
      // console.log('photo', photo, ele);
      return (
        <Row
          key={idx}
          style={{
            borderWidth: 1,
            padding: Metrics.baseMargin,
            marginHorizontal: 0,
            borderColor: Colors.lightGrey,
            marginBottom: Metrics.baseMargin,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 90,
            }}
          >
            {icon !== 'file-image-o' ? (
              <View
                style={{
                  width: 90,
                  height: 90,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FAIcon name={icon} size={80} />
                <View style={{ position: 'absolute' }}>{plusText}</View>
              </View>
            ) : (
              <ImageBackground
                source={{ uri: photo }}
                style={{
                  // position: 'absolute',
                  width: 90,
                  height: 90,
                  // resizeMode: 'contain',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {plusText}
              </ImageBackground>
            )}
          </Col>
          <Col
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Text numberOfLines={2} style={Fonts.style.normal}>
              {ele.keywordValue
                ? docTypes[documentType][ele.keywordValue].value ||
                  docTypes[documentType][ele.keywordValue].keywordValue
                : docTypes[documentType].documentType}
            </Text>
          </Col>
          <Col
            xs={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TouchableOpacity
              style={{
                // display: 'inline-block',
                paddingTop: Metrics.baseMargin,
                paddingBottom: Metrics.baseMargin,
                paddingLeft:
                  Platform.OS === 'web'
                    ? Metrics.doubleBaseMargin
                    : Metrics.baseMargin,
                paddingRight:
                  Platform.OS === 'web'
                    ? Metrics.doubleBaseMargin
                    : Metrics.baseMargin,
                marginRight: Metrics.baseMargin,
                borderRadius: 5,
                backgroundColor: '#aaa',
              }}
              onPress={this.handleDeleteFiles.bind(this, key)}
            >
              <Icon name="md-trash" size={20} />
            </TouchableOpacity>
          </Col>
        </Row>
      );
      // }
    });

    return (
      rows && (
        <View>
          <Row style={{ padding: Metrics.baseMargin, marginHorizontal: 0 }}>
            <Col
              xs={3}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <FormattedMessage
                id="Image"
                style={[Fonts.style.normal, { fontWeight: 'bold' }]}
              />
            </Col>
            <Col
              xs={9}
              style={{ justifyContent: 'center', alignItems: 'flex-start' }}
            >
              <FormattedMessage
                id="Type"
                style={[Fonts.style.normal, { fontWeight: 'bold' }]}
              />
            </Col>
          </Row>
          {rows}
        </View>
      )
    );
  }

  render() {
    const {
      application,
      base,
      index,
      initilized,
      documents,
      fetching,
      userId,
      tokenId,
      token,
      onComplete,
      onCancel,
      onSkip,
      single,
      documentType,
      documentKeywordValue,
    } = this.props;

    const { errorText } = this.state;

    // console.log('fetching', fetching);
    // console.log('errorText', errorText);

    const personId = getPersonId(application, base, index);

    const photos = [];
    const icons = [];
    documents &&
      documents.forEach((ele) => {
        if (
          ele &&
          ele.personId === personId &&
          ele.documentType === documentType &&
          ele.keywordValue === documentKeywordValue
        ) {
          const icon = getDocumentIcon(ele.fileName);
          const photo = getDocumentURL(
            ele.fileDownloadURL,
            userId,
            tokenId,
            token,
          );
          photos.push(photo);
          icons.push(icon);
        }
      });
    if (single && photos.length > 0) {
      console.log('single photos', JSON.stringify(photos, null, 2));

      const trash = (
        <TouchableOpacity
          style={{
            // display: 'inline-block',
            paddingTop: Metrics.baseMargin,
            paddingBottom: Metrics.baseMargin,
            paddingLeft:
              Platform.OS === 'web'
                ? Metrics.doubleBaseMargin
                : Metrics.baseMargin,
            paddingRight:
              Platform.OS === 'web'
                ? Metrics.doubleBaseMargin
                : Metrics.baseMargin,
            marginRight: Metrics.baseMargin,
            borderRadius: 5,
            backgroundColor: '#aaa',
          }}
          onPress={this.handleDeleteFiles.bind(this, documentKeywordValue)}
        >
          <Icon name="md-trash" size={20} />
        </TouchableOpacity>
      );

      return (
        <View style={styles.flex}>
          <Row>
            <Col sm={12}>
              <View style={{ borderWidth: 1, borderColor: '#000' }}>
                {icons[0] !== 'file-image-o' ? (
                  <View
                    style={{
                      height: 250,
                      flexDirection: 'column',

                      paddingBottom: Metrics.baseMargin,
                    }}
                  >
                    <View
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                      }}
                    >
                      <FAIcon name={icons[0]} size={90} />
                    </View>
                    <View
                      style={{
                        alignItems: 'flex-end',
                      }}
                    >
                      {trash}
                    </View>
                  </View>
                ) : (
                  <ImageBackground
                    style={{
                      height: 250,
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      paddingBottom: Metrics.baseMargin,
                    }}
                    resizeMode="contain"
                    source={{ uri: photos[0] }}
                  >
                    {trash}
                  </ImageBackground>
                )}
              </View>
            </Col>
          </Row>
        </View>
      );
    }

    return (
      <View style={styles.flex}>
        {this.renderDocuments(documents)}
        <Row>
          <Col sm={12}>
            <ErrorMessage errorText={errorText} />
            <CaptureUploadDocuments
              personId={personId}
              documentType={documentType}
              documents={[]}
              setDocuments={this.handleUploadFiles}
              deleteDocuments={this.handleDeleteFiles}
              multiple
            />
          </Col>
        </Row>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

// UploadDocumentType = withFormik({
//   mapPropsToValues: props => {
//     const { base, index, application, change, token, account } = props;

//     let newApplication = JSON.parse(JSON.stringify(application));

//     // console.log('loaded application', JSON.stringify(newApplication, null, 2));
//     return newApplication;
//   },

//   // validate: (values, props) => {
//   //   // console.log('validating', values);
//   //   const errors = {};
//   //   // if (
//   //   //   R.path(['contact', 'preferredContactMethod'], values) &&
//   //   //   values.contact.preferredContactMethod === '3' &&
//   //   //   !R.path(['contact', 'email'], values)
//   //   // ) {
//   //   //   errors[CONTACT_BASE] = {};
//   //   //   errors[CONTACT_BASE].email = 'emailRequiredMethod';
//   //   // }
//   //   const { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear } = values.tmp;
//   //   if (DateOfBirthMonth && DateOfBirthDay && DateOfBirthYear) {
//   //     const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;
//   //     const dateOfBirth = moment(dob, 'M-D-YYYY');
//   //     if (!dateOfBirth.isValid()) {
//   //       errors.DateOfBirth = 'error.invalidDate';
//   //     }
//   //   }

//   //   return errors;
//   // },

//   // validationSchema,
//   // Submission handler
//   handleSubmit: (values, { setStatus, props }) => {
//     const { base, index } = props;
//     // console.log('handleSubmit', JSON.stringify(values, null, 2));

//     // let newValues = Immutable.asMutable(values) // Does not work
//     const newValues = Object.assign({}, values);

//     console.log('updateApplication', JSON.stringify(newValues, null, 2));
//     props.updateApplication(newValues);
//     setStatus('updateApplication');
//   }
// })(UploadDocumentType);

const mapStateToProps = ({
  app: { initilized },
  persist: { account, tokenId, token, application, options, docTypes },
  document: { documents, fetching, error },
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  saveApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId,
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId,
      ),
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentType);
