import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
// import I18n from 'react-native-i18n';

import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';

// import DefaultProgramList from './DefaultProgramList.json';
// import DefaultPickerEn from './DefaultPickerEn.json';
// import DefaultPickerEs from './DefaultPickerEs.json';
// import DefaultDocTypesEn from './DefaultDocTypesEn.json';
// import DefaultDocTypesEs from './DefaultDocTypesEs.json';
// import DefaultScreeningQuestionsEn from './DefaultScreeningQuestionsEn.json';
// import DefaultScreeningQuestionsEs from './DefaultScreeningQuestionsEs.json';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  OptionsLoadProgramCodes: null,
  OptionsLoadOptions: ['programCode'],
  OptionsLoadDocTypes: ['programCode'],
  OptionsLoadScreeningQuestions: ['programCode'],
  OptionsLoadOptionsSuccess: ['allOptions'],
  OptionsLoadDocTypesSuccess: ['allDocTypes'],
  OptionsLoadScreeningQuestionsSuccess: ['allScreeningQuestions'],

  OptionsSuccess: ['data'],
  OptionsFailure: ['error'],

  OptionsSetProgramCode: ['programCode'],

  OptionsSetOptions: ['options'],
  OptionsSetDocTypes: ['docTypes'],
  OptionsSetScreeningQuestions: ['screeningQuestions'],

  OptionsSet: ['options'],
  DocTypesSet: ['docTypes'],
  ScreeningQuestionsSet: ['screeningQuestions'],

  OptionsSetFetched: ['fetched'],
});

export const OptionsTypes = Types;
export default Creators;

/* ------------- Selectors ------------- */
export const parseProgramCodesJson = (en, es) => {
  const programCodes = { en: {}, es: {} };
  en.payload.forEach((ele) => {
    programCodes.en[ele.programCode] = {
      pickList: 'program',
      locale: 'en',
      code: ele.programCode,
      value: ele.programName,
    };
  });
  es.payload.forEach((ele) => {
    programCodes.es[ele.programCode] = {
      pickList: 'program',
      locale: 'es',
      code: ele.programCode,
      value: ele.programName,
    };
  });
  return programCodes;
};

export const parsePickerJson = (en, es) => {
  const options = {};
  en.payload.forEach((ele) => {
    if (!options[ele.locale]) {
      options[ele.locale] = {};
    }
    if (!options[ele.locale][ele.pickList]) {
      options[ele.locale][ele.pickList] = {};
    }
    options[ele.locale][ele.pickList][ele.code] = ele;
  });
  es.payload.forEach((ele) => {
    if (!options[ele.locale]) {
      options[ele.locale] = {};
    }
    if (!options[ele.locale][ele.pickList]) {
      options[ele.locale][ele.pickList] = {};
    }
    options[ele.locale][ele.pickList][ele.code] = ele;
  });
  return options;
};

export const parseDocTypesJson = (en, es) => {
  const options = {};

  en.payload[0].documents.forEach((ele) => {
    if (ele.keywordLocale) {
      if (!options[ele.keywordLocale]) {
        options[ele.keywordLocale] = {};
      }
      if (!options[ele.keywordLocale][ele.documentTypeCode]) {
        options[ele.keywordLocale][ele.documentTypeCode] = {};
      }
      options[ele.keywordLocale][ele.documentTypeCode][
        ele.keywordValueCode
      ] = ele;
    } else if (ele.documentLocale) {
      if (!options[ele.documentLocale]) {
        options[ele.documentLocale] = {};
      }
      options[ele.documentLocale][ele.documentTypeCode] = ele;
    }
  });
  es.payload[0].documents.forEach((ele) => {
    if (ele.keywordLocale) {
      if (!options[ele.keywordLocale]) {
        options[ele.keywordLocale] = {};
      }
      if (!options[ele.keywordLocale][ele.documentTypeCode]) {
        options[ele.keywordLocale][ele.documentTypeCode] = {};
      }
      options[ele.keywordLocale][ele.documentTypeCode][
        ele.keywordValueCode
      ] = ele;
    } else if (ele.documentLocale) {
      if (!options[ele.documentLocale]) {
        options[ele.documentLocale] = {};
      }
      options[ele.documentLocale][ele.documentTypeCode] = ele;
    }
  });
  return options;
};

export const parseScreeningQuestionsJson = (en, es) => {
  const options = { en: en.payload, es: es.payload };
  return options;
};

/* ------------- Initial State ------------- */
const languageCode = DEFAULT_LANGUAGE;

// const allOptions = parsePickerJson(DefaultPickerEn, DefaultPickerEs);
// const allDocTypes = parseDocTypesJson(DefaultDocTypesEn, DefaultDocTypesEs);
// const allScreeningQuestions = parseScreeningQuestionsJson(
//   DefaultScreeningQuestionsEn,
//   DefaultScreeningQuestionsEs
// );

export const INITIAL_STATE = Immutable({
  programCode: '',
  // allPrograms: parsePickerJson(DefaultPickerEn, DefaultPickerEs),
  // programs: parsePickerJson(DefaultDocTypesEn, DefaultDocTypesEs)[languageCode],
  // allOptions,
  // options: {},
  // allDocTypes,
  // docTypes: {},
  // allScreeningQuestions,
  // screeningQuestions: {},
  data: null,
  error: null,
  fetching: false,
  fetched: false, // This flag tells the Components not to try and fetch again.
});

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = (state) =>
  state.merge({ error: null, fetching: true, data: null });

export const programsSuccess = (state, { allPrograms }) =>
  state.merge({ fetching: false, error: null, allPrograms });

export const optionsSuccess = (state, { allOptions }) =>
  state.merge({ fetching: false, error: null, allOptions });

export const docTypesSuccess = (state, { allDocTypes }) =>
  state.merge({ fetching: false, error: null, allDocTypes });

export const screeningQuestionsSuccess = (state, { allScreeningQuestions }) =>
  state.merge({ fetching: false, error: null, allScreeningQuestions });

export const success = (state, { data }) =>
  state.merge({ fetching: false, error: null, data });
// we've had a problem with our API call.
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error: error || 'Unknown Error', data: null });

export const reset = (state) => INITIAL_STATE;

export const setProgramCode = (state, { programCode }) =>
  state.merge({ programCode });

export const setOption = (state, { options }) => state.merge({ options });
export const setPrograms = (state, { programs }) => state.merge({ programs });
export const setDocTypes = (state, { docTypes }) => state.merge({ docTypes });
export const setScreeningQuestions = (state, { screeningQuestions }) =>
  state.merge({ screeningQuestions });

export const setFetched = (state, { fetched }) => state.merge({ fetched });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPTIONS_LOAD_PROGRAM_CODES]: request,
  [Types.OPTIONS_LOAD_OPTIONS]: request,
  [Types.OPTIONS_LOAD_DOC_TYPES]: request,
  [Types.OPTIONS_LOAD_SCREENING_QUESTIONS]: request,
  [Types.OPTIONS_LOAD_OPTIONS_SUCCESS]: optionsSuccess,
  [Types.OPTIONS_LOAD_DOC_TYPES_SUCCESS]: docTypesSuccess,
  [Types.OPTIONS_LOAD_SCREENING_QUESTIONS_SUCCESS]: screeningQuestionsSuccess,

  [Types.OPTIONS_SUCCESS]: success,
  [Types.OPTIONS_FAILURE]: failure,

  [Types.OPTIONS_SET_PROGRAM_CODE]: setProgramCode,
  [Types.OPTIONS_SET_OPTIONS]: setOption,
  [Types.OPTIONS_SET_DOC_TYPES]: setDocTypes,
  [Types.OPTIONS_SET_SCREENING_QUESTIONS]: setScreeningQuestions,

  [Types.OPTIONS_SET]: setOption,
  [Types.DOC_TYPES_SET]: setDocTypes,
  [Types.SCREENING_QUESTIONS_SET]: setScreeningQuestions,

  [Types.OPTIONS_SET_FETCHED]: setFetched,
});

/* ------------- Selectors ------------- */
