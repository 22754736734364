import React, { Component, useState } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';

import { View } from 'react-native';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';
import { merge } from 'lodash';

import { Grid, Row, Col } from '../Components/Grid';
import { isRequired } from '../Lib/Utils';

import { updateApplicationAsync } from '../Sagas/ApplicationThunk';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';

// Styles
import { Fonts, Metrics } from '../Themes';
import styles from './Styles/ScreenStyles';

import {
  YES_OPTION,
  NO_OPTION,
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE,
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from '../Components/Footer';
import FormGroupText from '../Components/FormGroupTextInput';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import HookRadioInput from '../Components/HookRadioInput';
import HookTextInput from '../Components/HookTextInput';
import ErrorMessage from '../Components/ErrorMessage';

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE = 'enterHome';

export const FloodWaterEnterHomeScreenComplete = (application) => {
  return R.pathOr('N/A', ['didWaterEnterHomeCode'], application) !== 'N/A';
};

export default function FloodWaterEnterHomeScreen(props) {
  // state = {
  //   errorText: null,
  // };

  // updatingApplication = false;

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const {
  //     fetching,
  //     errorText,
  //     application,
  //     isSubmitting,
  //     status,
  //     setStatus,
  //     setSubmitting,
  //     history,
  //   } = nextProps;

  //   if (isSubmitting && status === 'updatingApplication' && !fetching) {
  //     setStatus('');
  //     setSubmitting(false);
  //     if (errorText) {
  //       this.setState({ errorText });
  //     } else {
  //       history.push('InsuranceScreen');
  //     }
  //   }
  // }

  const dispatch = useDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { application } = useSelector((state) => state.persist);

  console.log('FloodWaterEnterHomeScreen -> application', application);
  const { handleSubmit, control, errors, watch } = useForm({
    defaultValues: application || {},
  });

  const onSubmit = async (values) => {
    // const newApplication = { ...application, ...values };
    const newApplication = merge(
      JSON.parse(JSON.stringify(application)),
      values,
    );
    if (newApplication.didWaterEnterHomeCode === NO_OPTION) {
      newApplication.didWaterReachOutletsCode = NO_OPTION;
      newApplication.howMuchWaterEnteredHome = '';
    }
    console.log('newApplication', newApplication);
    setIsSubmitting(true);
    setErrorText(null);
    const results = await dispatch(
      updateApplicationAsync({ application: newApplication }),
    );
    console.log('results', results);
    if (results.error) {
      setErrorText(results.error);
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      history.push('/InsuranceScreen');
    }
  };

  // const isMobileHome =
  //   R.path([BASE_DAMAGED_ADDRESS, 'propertyType'], application) ===
  //   MOBILE_HOME_CODE;
  const floodWaterEnterHome = watch('didWaterEnterHomeCode');

  return (
    <View style={styles.mainContainer}>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id="DamagedAddress.WaterEnterHome"
                style={Fonts.style.title}
              />
              <FormattedMessage
                id="DamagedAddress.WaterEnterHomeDescription"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <ErrorMessage errorText={(!isSubmitting && errorText) || ''} />
              <HookRadioInput
                control={control}
                field="didWaterEnterHomeCode"
                labelId=""
                optionsKey="yes_no"
                required
                error={errors?.didWaterEnterHomeCode}
              />
            </Col>
          </Row>

          {floodWaterEnterHome === YES_OPTION && (
            <View>
              <Row>
                <Col>
                  <FormattedMessage
                    id="DamagedAddress.ReachOutletsDescription"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <HookRadioInput
                    control={control}
                    field="didWaterReachOutletsCode"
                    labelId=""
                    optionsKey="yes_no"
                    required
                    error={errors?.didWaterReachOutletsCode}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormattedMessage
                    id="DamagedAddress.EstimatedDepthDescription"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <HookTextInput
                    control={control}
                    field={`howMuchWaterEnteredHome`}
                    labelId=""
                    placeholderId="DamagedAddress.FeetInchesPlaceholder"
                    required
                    error={errors?.howMuchWaterEnteredHome}
                  />
                </Col>
              </Row>
            </View>
          )}

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                disabled={isSubmitting}
                onPress={handleSubmit(onSubmit)}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    </View>
  );
}

// FloodWaterEnterHomeScreen = withFormik({
//   mapPropsToValues: (props) => {
//     const { application } = props;

//     return application;
//   },
//   // isInitialValid: (props) => true,
//   // validationSchema,
//   // Submission handler
//   handleSubmit: (values, { setStatus, props }) => {
//     const { updateApplication } = props;
//     console.log('updatingApplication', values);
//     updateApplication(values);
//     setStatus('updatingApplication');
//   },
// })(FloodWaterEnterHomeScreen);

// const mapStateToProps = ({
//   app: { initilized },
//   application: { fetching, error },
//   persist: { options, application },
// }) => ({
//   options,
//   initilized,
//   fetching,
//   errorText: error,
//   application,
//   initialValues: application,
// });

// const mapDispatchToProps = (dispatch) => ({
//   updateApplication: (application) =>
//     dispatch(ApplicationActions.ApplicationUpdate(application)),
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(FloodWaterEnterHomeScreen);
