export default {
  proofOfIdentification: {
    8: {
      code: '8010',
      keywords: {
        DriversLicense: '8001',
      },
    },
    9: {
      code: '8010',
      keywords: {
        DriversLicense: '8001',
      },
    },
  },
  primaryResidence: {
    8: {
      code: '8040',
    },
    9: {
      code: '8040',
    },
  },
  proofOfInsurance: {
    8: {
      code: '8020',
    },
    9: { code: '8020' },
  },
  proofOfOwnership: {
    8: {
      code: '8030',
      traditionalDocumentsCodes: ['8021', '8022', '8023', '8024'],
      nonTraditionalDocumentsCodes: ['8025', '8026', '8027'],
    },
    9: {
      code: '8030',
      traditionalDocumentsCodes: ['8021', '8022', '8023', '8024'],
      nonTraditionalDocumentsCodes: ['8025', '8026', '8027'],
    },
  },
  proofOfCitizenship: {
    8: {
      code: '8015',
    },
    9: {
      code: '8015',
    },
  },
  proofOfDisability: {
    8: {
      code: '8065',
    },
    9: {
      code: '8065',
    },
  },
  // Unused for FL
  proofOfLandOwnership: {
    // 8: {
    //   code: '7410',
    // },
    // 9: {
    //   code: '7410',
    // },
  },
  // Unused for FL
  tenantLeaseAgreement: {
    // 9: {
    //   code: '7400',
    // },
  },
  // proofOfMobileHomeOwnership: {
  //   code: '4008'
  // },
  proofOfPrimaryResidence: {
    8: {
      code: '8040',
    },
    9: {
      code: '8040',
    },
  },
  proofOfPropertyDamage: {
    8: {
      code: '8050',
      keywords: {
        proofOfInsurancePayout: '8065',
      },
    },
    9: {
      code: '8050',
      keywords: {
        proofOfInsurancePayout: '8065',
      },
    },
  },
  proofOfIncome: {
    8: {
      code: '8060',
    },
    9: {
      code: '8060',
    },
  },
  stateTaxReturn: {
    8: {
      code: '8070',
    },
    9: {
      code: '8070',
    },
  },
  federalTaxReturn: {
    8: {
      code: '8080',
    },
    9: {
      code: '8080',
    },
  },
  signatureNoIncome: {
    8: {
      code: '8210',
    },
    9: {
      code: '8210',
    },
  },
  signature4506T: {
    8: {
      code: '8230',
    },
    9: {
      code: '8230',
    },
  },
  signatureLawfulPresence: {
    8: {
      code: '8200',
    },
    9: {
      code: '8200',
    },
  },
  signatureSubrogationAgreement: {
    8: {
      code: '8250',
    },
    9: {
      code: '8250',
    },
  },
  signatureNoInsurance: {
    8: {
      code: '8220',
    },
    9: {
      code: '8220',
    },
  },
  communicationDesigneeAuthorization: {
    8: {
      code: '8240',
    },
    9: {
      code: '8240',
    },
  },
  powerOfAttorney: {
    8: {
      code: '8100',
    },
    9: {
      code: '8100',
    },
  },
  lienDocument: {
    8: {
      code: '8090',
    },
    9: {
      code: '8090',
    },
  },
  docusignNoIncome: {
    8: {
      code: '8310',
    },
    9: {
      code: '8310',
    },
  },
  docusign4506T: {
    8: {
      code: '8330',
    },
    9: {
      code: '8330',
    },
  },
  docusignLawfulPresence: {
    8: {
      code: '8300',
    },
    9: {
      code: '8300',
    },
  },
  docusignSubrogationAgreement: {
    8: {
      code: '8350',
    },
    9: {
      code: '8350',
    },
  },
  docusignNoInsurance: {
    8: {
      code: '8320',
    },
    9: {
      code: '8320',
    },
  },
  docusignCommunicationDesignee: {
    8: {
      code: '8340',
    },
    9: {
      code: '8340',
    },
  },
};
