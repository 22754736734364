import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

const ApplicationRoute = ({ component: Component, ...rest }) => {
  const { application, account, tokenId, token, isKiosk } = useSelector(
    (state) => state.persist,
  );
  const { userId } = account || {};
  const hasApplication = application && application.id;
  const isLoggedIn = isKiosk
    ? hasApplication && userId
    : hasApplication && userId && token && tokenId;

  return (
    <Route
      {...rest}
      render={(params) => {
        const { location } = params;
        // Not Logged In
        if (!isLoggedIn)
          return (
            <Redirect
              to={{
                pathname: '/LoginScreen',
                state: { from: location },
              }}
            />
          );

        // Logged In
        return <Component {...params} />;
      }}
    />
  );
};

export default ApplicationRoute;
