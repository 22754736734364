import React, { Component, useState } from 'react';
import {
  Image,
  TouchableOpacity,
  View,
  Text,
  Platform,
  ScrollView,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import * as R from 'ramda';
import { connect, useSelector } from 'react-redux';
// import SignatureCapture from 'react-native-signature-capture';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, {
  getDocumentURL,
  getDocumentType,
} from '../Redux/DocumentRedux';

import CaptureSignature from '../Components/CaptureSignature';
import FormattedMessage from '../Components/FormattedMessage';
import FullButton from '../Components/FullButton';

import ConsentToShareDataForm from './ConsentToShareDataForm';

// Styles
import { Fonts, Colors, Metrics } from '../Themes';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import Footer from '../Components/Footer';
import { getPersonId, hasSignatureDocument } from '../Lib/Utils';
import { useHistory } from 'react-router';

const DOCUMENT_TYPE = 'signatureSubrogationAgreement';
const CONSENT_TO_SHARE_DATA_KEYWORD_VALUE = '';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;

export const ConsentToShareDataScreenComplete = (application, documents) => {
  const applicantPersonId = getPersonId(application, APPLICANT_BASE);
  const documentType = getDocumentType(application, DOCUMENT_TYPE);

  const hasSignature = hasSignatureDocument(
    documents,
    applicantPersonId,
    documentType.code,
  );

  return hasSignature;
};

export default function ConsentToShareDataScreenfunction() {
  // componentWillReceiveProps(nextProps) {
  //   const { fetching, error, navigation } = nextProps;

  //   if (this.uploadingDocuments && !fetching) {
  //     this.uploadingDocuments = false;
  //     if (error) {
  //       this.setState({ errorText: error });
  //     } else {
  //       navigation.navigate('ApplicationChecklistScreen');
  //     }
  //   }
  // }

  // const {
  //   userId,
  //   tokenId,
  //   token,
  //   documents,
  //   application,
  // } = this.props;

  const { account, token, tokenId, documents, application } = useSelector(
    (state) => state.persist,
  );
  const { userId } = account || {};

  const history = useHistory();

  const [signing, setSigning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const errorView = errorText && (
    <View style={styles.errorContainer}>
      <FormattedMessage id="error.submissionError" style={styles.errorText} />
      <Text style={styles.errorText}>{errorText}</Text>
    </View>
  );

  const personId =
    R.pathOr(0, [APPLICANT_BASE, 'id'], application) > 0
      ? R.path([APPLICANT_BASE, 'id'], application)
      : APPLICANT_BASE;

  const documentType = getDocumentType(application, DOCUMENT_TYPE);
  let signatureImage = null;
  documents &&
    documents.forEach((ele) => {
      if (ele.documentType === documentType && ele.personId === personId) {
        signatureImage = getDocumentURL(
          ele.fileDownloadURL,
          userId,
          tokenId,
          token,
        );
      }
    });

  console.log('personId', personId);

  return (
    <View style={styles.mainContainer}>
      <ScrollView
        scrollEnabled={!signing}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="DamagedAddress.ConsentToShareData"
                style={Fonts.style.title}
              />
            </Col>
          </Row>
          <ConsentToShareDataForm />
          <Row>
            <Col>{errorView}</Col>
          </Row>
          {/* <Row>
              <Col sm={12}>
                <FormattedMessage
                  id={
                    Platform.OS === 'web'
                      ? 'buttons.SignWithFingerWeb'
                      : 'buttons.SignWithFinger'
                  }
                  style={Fonts.style.sectionHeader}
                />
              </Col>
            </Row> */}

          <Row>
            <Col
              sm={12}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <CaptureSignature
                base={APPLICANT_BASE}
                documentType={documentType.code}
                documentKeywordValue={CONSENT_TO_SHARE_DATA_KEYWORD_VALUE}
                labelId="form.continueNextStep"
                checkboxLabelId="form.AcceptAboveTermsAndConditions"
                onComplete={() => {
                  console.log('onComplete - navigating to CertificationOfCitizenshipScreen');
                  history.push('/CertificationOfCitizenshipScreen');
                }}
              />
            </Col>
          </Row>
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </ScrollView>
    </View>
  );
}

// const mapStateToProps = ({
//   options: { options },
//   app: { initilized },
//   account: { account, tokenId, token },
//   persist: { application },
//   document: { documents, error, fetching },
// }) => ({
//   userId: R.path(['userId'], account),
//   tokenId,
//   token,
//   documents,
//   error,
//   fetching,
//   options,
//   initilized,
//   application,
//   initialValues: application,
// });

// const mapDispatchToProps = (dispatch) => ({
//   saveApplication: (application) =>
//     dispatch(ApplicationActions.ApplicationSuccess(application)),
//   loadDocuments: (docType) =>
//     dispatch(DocumentActions.DocumentLoadDocuments(docType)),
//   uploadSignature: (personId, signature, documentTypeCode, keywordValueCode) =>
//     dispatch(
//       DocumentActions.DocumentUploadSignature(
//         personId,
//         signature,
//         documentTypeCode,
//         keywordValueCode,
//       ),
//     ),
//   uploadDocuments: (
//     personId,
//     documents,
//     documentTypeCode,
//     keywordValueCode,
//     documentId,
//   ) =>
//     dispatch(
//       DocumentActions.DocumentUpload(
//         personId,
//         documents,
//         documentTypeCode,
//         keywordValueCode,
//         documentId,
//       ),
//     ),
//   deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
//     dispatch(
//       DocumentActions.DocumentDelete(
//         personId,
//         documentTypeCode,
//         keywordValueCode,
//       ),
//     ),
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(ConsentToShareDataScreen);
