import { call, put, select } from 'redux-saga/effects';
import * as R from 'ramda';
import OptionsActions, {
  parseProgramCodesJson,
  parsePickerJson,
  parseDocTypesJson,
  parseScreeningQuestionsJson,
} from '../Redux/OptionsRedux';
import PersistActions from '../Redux/PersistRedux';

import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';

export const getProgramCodesAsync = () => async (dispatch, getState, api) => {
  const locale = getState()?.i18n?.locale || 'en';

  let enResults = '';
  let esResults = '';
  try {
    enResults = await api.getProgramCodes('en');
    esResults = await api.getProgramCodes('es');

    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const allProgramCodes = parseProgramCodesJson(
        enResults.data,
        esResults.data,
      );
      // console.log(
      //   'TCL: function*getProgramCodes -> allProgramCodes',
      //   allProgramCodes
      // );

      console.log('saving program codes', locale || DEFAULT_LANGUAGE);
      await dispatch(
        PersistActions.PersistSetProgramCodes(
          allProgramCodes[locale || DEFAULT_LANGUAGE],
        ),
      );
      await dispatch(PersistActions.PersistSetAllProgramCodes(allProgramCodes));
      await dispatch(OptionsActions.OptionsSuccess(allProgramCodes));
    } else {
      await dispatch(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      await dispatch(OptionsActions.OptionsFailure(error));
    }
  }
};
export const getOptionsAsync = ({ programCode }) => async (
  dispatch,
  getState,
  api,
) => {
  const locale = getState()?.i18n?.locale || 'en';
  const { allOptions } = getState().persist;

  let enResults = '';
  let esResults = '';
  try {
    enResults = await api.getPickList(programCode, 'en');
    // console.log('TCL: function*getOptions -> enResults', enResults);
    esResults = await api.getPickList(programCode, 'es');
    // console.log('TCL: function*getOptions -> esResults', esResults);

    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const newOptions = allOptions.set(
        programCode,
        parsePickerJson(enResults.data, esResults.data),
      );

      console.log('saving options', locale || DEFAULT_LANGUAGE);
      await dispatch(
        PersistActions.PersistSetOptions(
          newOptions[programCode][locale || DEFAULT_LANGUAGE],
        ),
      );
      await dispatch(PersistActions.PersistSetAllOptions(newOptions));
      await dispatch(OptionsActions.OptionsSuccess(newOptions));
      await dispatch(OptionsActions.OptionsSetProgramCode(programCode));
    } else {
      await dispatch(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      await dispatch(OptionsActions.OptionsFailure(error));
    }
  }
};

export const getDocTypesAsync = ({ programCode }) => async (
  dispatch,
  getState,
  api,
) => {
  const locale = getState()?.i18n?.locale || 'en';
  const { allDocTypes } = getState().persist;

  let enResults = '';
  let esResults = '';
  // console.log(api, programCode);
  try {
    enResults = await api.getKeywordsOptionsByDocType(programCode, 'en');
    esResults = await api.getKeywordsOptionsByDocType(programCode, 'es');
    // console.log(
    //   'getDocTypes results',
    //   JSON.stringify({ enResults, esResults }, null, 2)
    // );
    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const newDocTypes = allDocTypes.set(
        programCode,
        parseDocTypesJson(enResults.data, esResults.data),
      );

      console.log('saving DocTypes', locale || DEFAULT_LANGUAGE);
      await dispatch(
        PersistActions.PersistSetDocTypes(
          newDocTypes[programCode][locale || DEFAULT_LANGUAGE],
        ),
      );
      await dispatch(PersistActions.PersistSetAllDocTypes(newDocTypes));
      await dispatch(OptionsActions.OptionsSuccess(newDocTypes));
      await dispatch(OptionsActions.OptionsSetProgramCode(programCode));
    } else {
      await dispatch(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    console.log(error);
    if (R.path(['data', 'payload'], error)) {
      await dispatch(OptionsActions.OptionsFailure(error));
    }
  }
};

export const getScreeningQuestionsAsync = ({ programCode }) => async (
  dispatch,
  getState,
  api,
) => {
  const locale = getState()?.i18n?.locale || 'en';
  const { allScreeningQuestions } = getState().persist;

  let enResults = '';
  let esResults = '';
  try {
    enResults = await api.getScreeningQuestions(programCode, 'en');
    esResults = await api.getScreeningQuestions(programCode, 'es');
    // console.log(
    //   'getScreeningQuestions results',
    //   JSON.stringify({ enResults, esResults }, null, 2)
    // );
    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const newScreeningQuestions = allScreeningQuestions.set(
        programCode,
        parseScreeningQuestionsJson(enResults.data, esResults.data),
      );

      console.log('saving Screening Questions', locale || DEFAULT_LANGUAGE);
      await dispatch(
        PersistActions.PersistSetScreeningQuestions(
          newScreeningQuestions[programCode][locale || DEFAULT_LANGUAGE],
        ),
      );
      await dispatch(
        PersistActions.PersistSetAllScreeningQuestions(newScreeningQuestions),
      );
      await dispatch(OptionsActions.OptionsSuccess(newScreeningQuestions));
    } else {
      await dispatch(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      await dispatch(OptionsActions.OptionsFailure(error));
    }
  }
};
