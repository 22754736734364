import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from '../../Themes';

export default StyleSheet.create({
  topContainer: {
    flexDirection: 'column',
    // paddingTop: Metrics.safePaddingTop,
    backgroundColor: Colors.headerBackground,
  },
  barContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // height: Metrics.navBarHeight,
    backgroundColor: Colors.headerBackground,
  },
  iconContainer: {
    padding: Metrics.baseMargin,
    marginLeft: Metrics.baseMargin,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleLogoText: {
    flex: 1,
    // margin: 18,
    textAlign: 'center',
    color: Colors.logoText,
    fontSize: Fonts.size.pt50,
    fontFamily: Fonts.type.bold,
  },
  titleText: {
    ...Fonts.style.topnav,
    margin: Metrics.baseMargin / 2,
    textAlign: 'center',
    color: Colors.white,
  },
});
