/* eslint-disable import/no-useless-path-segments */
/* eslint-disable prefer-const */
/* eslint-disable import/no-self-import */
/* eslint-disable global-require */
import { combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { i18nReducer } from 'react-redux-i18n';
import { connectRouter } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import configureStore from './CreateStore';
import rootSaga from '../Sagas';
import ReduxPersist from '../Config/ReduxPersist';

/* ------------- Assemble The Reducers ------------- */
export const reducers = (history) =>
  combineReducers({
    app: require('./AppRedux').reducer,
    account: require('./AccountRedux').reducer,
    application: require('./ApplicationRedux').reducer,
    case: require('./CaseRedux').reducer,
    document: require('./DocumentRedux').reducer,
    // network: require('./NetworkRedux').reducer,
    options: require('./OptionsRedux').reducer,
    persist: require('./PersistRedux').reducer,

    router: connectRouter(history),
    i18n: i18nReducer,
  });

export default (history) => {
  // console.log('history', history);
  let finalReducers = reducers(history);

  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers(history));
  }

  let { store, persistor, sagasManager, sagaMiddleware } = configureStore(
    history,
    finalReducers,
    rootSaga,
  );

  // if (module.hot) {
  //   module.hot.accept(() => {
  //     const nextRootReducer = require('./').reducers;
  //     store.replaceReducer(nextRootReducer(history));

  //     const newYieldedSagas = require('../Sagas').default;
  //     sagasManager.cancel();
  //     sagasManager.done.then(() => {
  //       sagasManager = sagaMiddleware.run(newYieldedSagas);
  //     });
  //   });
  // }

  return { store, persistor };
};
