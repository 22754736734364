import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { Image, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Ionicons as Icon } from '@expo/vector-icons';

import { withFormik, Field } from 'formik';
import * as yup from 'yup';

import { isRequired, getPersonId } from '../Lib/Utils';

import ApplicationActions from '../Redux/ApplicationRedux';

import { Grid, Row, Col } from './Grid';
import FormattedMessage from './FormattedMessage';
import FormGroupRadio from './FormGroupRadio';
import FullButton from './FullButton';
import CaptureUploadDocuments from './CaptureUploadDocuments';
import SelectUploadDocumentType from './SelectUploadDocumentType';

import DocumentActions, {
  getDocumentURL,
  getDocumentType,
} from '../Redux/DocumentRedux';

// Styles
import { Fonts, Colors, Metrics } from '../Themes';

import styles from '../Containers/Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from './Footer';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;

const DOCUMENT_TYPE = 'proofOfIdentification';

class IdentityProof extends Component {
  render() {
    const {
      application,
      base,
      index,
      onComplete,
      documents,
      initilized,
      submitForm,
      isValid,
      isSubmitting,
      fetching,
    } = this.props;

    const documentType = getDocumentType(application, DOCUMENT_TYPE);

    return (
      <View style={styles.flex}>
        <KeyboardAwareScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="ProveIdentity.title"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormattedMessage
                  id="ProveIdentity.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <SelectUploadDocumentType
              base={base}
              index={index}
              documentType={documentType.code}
              onComplete={onComplete}
              horiz
            />
          </Grid>
          <View style={{ marginTop: Metrics.doubleBaseMargin }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const validationSchema = yup.object().shape({
  documentContents: yup
    .array()
    .min(1, 'error.required')
    .required('error.required'),
  documentType: yup
    .string()
    .min(1, 'error.required')
    .required('error.required'),
});

IdentityProof = withFormik({
  mapPropsToValues: (props) => props.application,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { props }) => {
    const { documentContents, documentType } = values;
    const { base, index, application, uploadDocuments } = props;
    // const personId = getPersonId(application, base, index);

    // console.log(personId, documentContents, DOCUMENT_TYPE, documentType);
    // uploadDocuments(personId, documentContents, DOCUMENT_TYPE, documentType);
  },
})(IdentityProof);

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, application, account, tokenId, token },
  document: { documents, fetching, error },
  form,
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  saveApplication: (application) =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId,
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId,
      ),
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode,
      ),
    ),
});

// IdentityProof = reduxForm({
//   destroyOnUnmount: true,
//   form: 'IdentityProof'
// })(IdentityProof);
export default connect(mapStateToProps, mapDispatchToProps)(IdentityProof);
