/**
 * Expose cross platform Grid, Row and Col similar to React Bootstrap
 */
import React from 'react';

import MyContainer from 'react-bootstrap/Container';
import MyRow from 'react-bootstrap/Row';
import MyCol from 'react-bootstrap/Col';

export const Grid = MyContainer;
export const Row = MyRow;
export const Col = MyCol;
