import React, { Component, useState } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import diff from 'deep-diff';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { View, Text, Platform, ScrollView } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { merge } from 'lodash';
import { Ionicons as Icon } from '@expo/vector-icons';

import ApplicationActions, {
  checkRentalProgram,
} from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentType } from '../Redux/DocumentRedux';

import { Grid, Row, Col } from '../Components/Grid';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FormGroupSelect from '../Components/FormGroupSelect';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import UploadDocumentType from '../Components/UploadDocumentType';
import HookSelectUploadDocumentType from '../Components/HookSelectUploadDocumentType';
import HookAddressForm from '../Components/HookAddressForm';

import {
  isRequired,
  isEmail,
  isEmailRule,
  isRequiredEmail,
  isMonth,
  isMonthRule,
  isDay,
  isDayRule,
  isPastYear,
  isPastYearRule,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  isPhoneNumberRule,
  phoneFormat,
  isZipcode,
  zipcodeFormat,
  getPersonId,
  getBase,
  getBaseString,
  objectByString,
} from '../Lib/Utils';
import validationSchema from '../Lib/ValidationSchema';

// Styles
import { Fonts, Metrics, Colors } from '../Themes';
import styles from './Styles/ScreenStyles';

import {
  DEFAULT_STATE_CODE,
  ADDRESS_TYPE_PO_BOX,
  ADDRESS_TYPE_RURAL_ROUTE,
  ADDRESS_TYPE_HIGHWAY_CONTRACT,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_APARTMENT,
  YES_OPTION,
  TRUE_OPTION,
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import Footer from '../Components/Footer';
import LoadingScreen from './LoadingScreen';
import HookTextInput from '../Components/HookTextInput';
import HookRadioInput from '../Components/HookRadioInput';
import HookSelectInput from '../Components/HookSelectInput';
import HookCheckboxInput from '../Components/HookCheckboxInput';
import { updateApplicationAsync } from '../Sagas/ApplicationThunk';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;
const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const TENANTS_BASE = DATA_STRUCTURE.tenants;
const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;

const CITIZENSHIP_DOCUMENT_TYPE = 'proofOfCitizenship';
const TENANT_LEASE_AGREEMENT_DOCUMENT_TYPE = 'tenantLeaseAgreement';
const DOCUMENT_TYPE = 'proofOfIdentification';
const DRIVERS_LICENSE_KEYWORD = 'DriversLicense';

export const ContactInfoComplete = (application, documents, base, index) => {
  const appBase = getBase(application, base, index);
  return R.pathOr('N/A', ['firstName'], appBase) !== 'N/A';
};

export default function ContactInfo(props) {
  // static propTypes = {
  //   base: PropTypes.string.isRequired,
  //   index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //   // navigation: PropTypes.object.isRequired,
  //   onComplete: PropTypes.func.isRequired,
  // };

  // state = {
  //   errorText: null,
  //   photo: null,
  //   width: null,
  //   height: null,
  // };

  // refs = {};

  // updatingApplication = false;

  // loadedDocuments = false;

  // loadingDocuments = false;

  // uploadingDocuments = false;

  // deletingDocuments = false;

  // formInitilized = false;

  // componentDidMount() {
  //   const { initilized, loadDocuments } = this.props;
  //   // loadDocuments(DOCUMENT_TYPE);
  //   // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
  //   //   this.formInitilized = true;
  //   //   this.initilizeForm(this.props);
  //   // }
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const {
  //     isSubmitting,
  //     setSubmitting,
  //     data,
  //     errorText,
  //     fetching,
  //     initilized,
  //     submitCount,
  //     onComplete,
  //     status,
  //     setStatus,
  //   } = nextProps;

  //   if (isSubmitting && status === 'updatingApplication' && !fetching) {
  //     setSubmitting(false);
  //     setStatus('');
  //     console.log('submitted', errorText);
  //     if (errorText) {
  //       this.setState({ errorText });
  //       // Scroll to top
  //       // if (refs._scroll) refs._scroll.scrollTo({ x: 0, y: 0 });
  //     } else {
  //       // setSubmitting(false);
  //       // console.log('onComplete', data);
  //       onComplete(data);
  //     }
  //   } else if (
  //     submitCount > 0 &&
  //     submitCount !== this.props.submitCount &&
  //     !this.props.isValid
  //   ) {
  //     this.setState({ errorText: errorText || I18n.t('error.formError') });
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.submitCount > 0 &&
  //     prevProps.submitCount !== this.props.submitCount &&
  //     !this.props.isValid
  //   ) {
  //     console.log(
  //       'this.props.submitCount',
  //       prevProps.submitCount,
  //       this.props.submitCount,
  //     );
  //     if (Platform.OS === 'web') {
  //       if (refs._scroll) refs._scroll.scrollTo({ x: 0, y: 0 });
  //     } else {
  //       setTimeout(() => {
  //         if (refs._scroll) refs._scroll.scrollTo({ x: 0, y: 0 });
  //       }, 300);
  //     }
  //   }
  // }

  const handleUploadFiles = (photos) => {
    // console.log(photos);

    const { application, index, base, uploadDocuments } = this.props;

    const personId = getPersonId(application, base, index);
    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    const documentKeywordValue = documentType.keywords[DRIVERS_LICENSE_KEYWORD];
    // index >= 0
    //   ? R.pathOr(0, [base, index, 'id'], application) > 0
    //     ? R.path([base, index, 'id'], application)
    //     : `${base}_${index}`
    //   : R.pathOr(0, [base, 'id'], application) > 0
    //   ? R.path([base, 'id'], application)
    //   : base;

    this.uploadDocuments = true;
    // console.log(personId, photos, documentType, documentKeywordValue);
    uploadDocuments(personId, photos, documentType, documentKeywordValue);
  };

  const handleDeleteFiles = () => {
    const { application, index, base, deleteDocuments } = this.props;

    const personId = getPersonId(application, base, index);
    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    const documentKeywordValue = documentType.keywords[DRIVERS_LICENSE_KEYWORD];
    // index >= 0
    //   ? R.pathOr(0, [base, index, 'id'], application) > 0
    //     ? R.path([base, index, 'id'], application)
    //     : `${base}_${index}`
    //   : R.pathOr(0, [base, 'id'], application) > 0
    //   ? R.path([base, 'id'], application)
    //   : base;

    this.deletingDocuments = true;
    console.log(
      'deleteDocuments',
      personId,
      documentType,
      documentKeywordValue,
    );
    deleteDocuments(personId, documentType, documentKeywordValue);
  };

  const handleMailingAddressCopy = () => {
    const {
      application,
      setFieldValue,
      values,
      base,
      index,
      options,
    } = this.props;
    const baseString = getBaseString(base, index);

    // console.log('copyCheckbox', values[BASE_DAMAGED_ADDRESS].copyCheckbox);

    const copyCheckbox = values.copyCheckbox === TRUE_OPTION;

    // setFieldValue(
    //   `${BASE_DAMAGED_ADDRESS}.copyCheckbox`,
    //   values[BASE_DAMAGED_ADDRESS].copyCheckbox
    // );

    const newState = !copyCheckbox;
    if (newState && application && application[BASE_DAMAGED_ADDRESS]) {
      console.log('copy');

      setFieldValue(
        `${baseString}.addressTypeCode`,
        application[BASE_DAMAGED_ADDRESS].addressTypeCode,
      );

      setFieldValue(
        `${baseString}.boxNumber`,
        application[BASE_DAMAGED_ADDRESS].boxNumber,
      );
      setFieldValue(
        `${baseString}.RRHCNumber`,
        application[BASE_DAMAGED_ADDRESS].RRHCNumber,
      );

      setFieldValue(
        `${baseString}.address`,
        application[BASE_DAMAGED_ADDRESS].address,
      );
      setFieldValue(
        `${baseString}.address2`,
        application[BASE_DAMAGED_ADDRESS].address2,
      );
      setFieldValue(
        `${baseString}.address3`,
        application[BASE_DAMAGED_ADDRESS].address3,
      );
      setFieldValue(
        `${baseString}.city`,
        application[BASE_DAMAGED_ADDRESS].city,
      );

      Object.keys(options.city).forEach((cityCode) => {
        const city = options.city[cityCode];
        console.log(
          `${city.value} === ${application[BASE_DAMAGED_ADDRESS].city} = ${
            city.value === application[BASE_DAMAGED_ADDRESS].city
          }`,
        );
        if (city.value === application[BASE_DAMAGED_ADDRESS].city) {
          setFieldValue(`${baseString}.cityCode`, cityCode);
        }
      });

      setFieldValue(
        `${baseString}.stateCode`,
        application[BASE_DAMAGED_ADDRESS].stateCode,
      );
      setFieldValue(`${baseString}.zip`, application[BASE_DAMAGED_ADDRESS].zip);
    } else {
      console.log(`clear ${baseString}.boxNumber`);
      setFieldValue(`${baseString}.boxNumber`, '');
      setFieldValue(`${baseString}.RRHCNumber`, '');
      setFieldValue(`${baseString}.address`, '');
      setFieldValue(`${baseString}.address2`, '');
      setFieldValue(`${baseString}.address3`, '');
      setFieldValue(`${baseString}.cityCode`, '');
      setFieldValue(`${baseString}.city`, '');
      setFieldValue(`${baseString}.stateCode`, DEFAULT_STATE_CODE);
      setFieldValue(`${baseString}.zip`, '');
    }

    // this.setState({ copyCheckbox: newState });
  };

  const { base, index, onComplete } = props;

  const dispatch = useDispatch();

  const [submitCount, setSubmitCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { account } = useSelector((state) => state.account);
  const { isKiosk, application } = useSelector((state) => state.persist);
  const { fetching } = useSelector((state) => state.application);
  const { documents } = useSelector((state) => state.document);

  const userId = account?.userId;

  const isRentalProgram = checkRentalProgram(application);

  const appBase = getBase(application, base, index);
  const baseString = getBaseString(base, index);

  const defaultValues = JSON.parse(JSON.stringify(application));
  // console.log('mapPropsToValues', JSON.stringify(defaultValues, null, 2));

  if (!defaultValues[CONTACT_BASE]) {
    defaultValues[CONTACT_BASE] = {};
  }
  if (!defaultValues[base]) {
    defaultValues[base] = {};
  }

  // Set Default State
  if (
    !defaultValues ||
    !defaultValues[CONTACT_BASE] ||
    !defaultValues[CONTACT_BASE].stateCode
  ) {
    // console.log('setting default state', CONTACT_BASE, DEFAULT_STATE_CODE);
    defaultValues[CONTACT_BASE].stateCode = DEFAULT_STATE_CODE;
    // change(`${CONTACT_BASE}.stateCode`, DEFAULT_STATE_CODE);
  }

  // Copy City String to City Code
  // if (
  //   defaultValues[CONTACT_BASE].stateCode === DEFAULT_STATE_CODE &&
  //   defaultValues[CONTACT_BASE].city &&
  //   options &&
  //   options.city
  // ) {
  //   Object.keys(options.city).forEach((cityCode) => {
  //     const city = options.city[cityCode];
  //     if (city.value === defaultValues[CONTACT_BASE].city) {
  //       defaultValues[CONTACT_BASE].cityCode = cityCode;
  //     }
  //   });
  // }

  if (base === APPLICANT_BASE) {
    // // Split Address
    // if (
    //   defaultValues[CONTACT_BASE] &&
    //   defaultValues[CONTACT_BASE].address
    // ) {
    //   const split = defaultValues[CONTACT_BASE].address.split('\n');

    //   defaultValues[CONTACT_BASE].address1 = split[0];
    //   defaultValues[CONTACT_BASE].address2 = split[2];
    //   // change(`${CONTACT_BASE}.address1`, split[0]);
    //   // change(`${CONTACT_BASE}.address2`, split[1]);
    // }

    // Set Default EMail if not Set
    if (
      !isKiosk &&
      userId &&
      account.emailAddress &&
      (!defaultValues[CONTACT_BASE] || !defaultValues[CONTACT_BASE].email)
    ) {
      defaultValues[CONTACT_BASE].email = account.emailAddress;
      // change(`${CONTACT_BASE}.email`, account.emailAddress);
    }
  }

  if (base === TENANTS_BASE) {
    // Copy City String to City Code
    // if (
    //   defaultValues[TENANTS_BASE][index].stateCode === DEFAULT_STATE_CODE &&
    //   defaultValues[TENANTS_BASE][index].city &&
    //   options &&
    //   options.city
    // ) {
    //   Object.keys(options.city).forEach((cityCode) => {
    //     const city = options.city[cityCode];
    //     if (city.value === defaultValues[TENANTS_BASE][index].city) {
    //       defaultValues[TENANTS_BASE][index].cityCode = cityCode;
    //     }
    //   });
    // }
    if (
      !defaultValues ||
      !defaultValues[TENANTS_BASE] ||
      !defaultValues[TENANTS_BASE][index] ||
      !defaultValues[TENANTS_BASE][index].stateCode
    ) {
      defaultValues[TENANTS_BASE][index].stateCode = DEFAULT_STATE_CODE;
    }
  }

  if (appBase && appBase.dateOfBirth) {
    const dob = moment(appBase.dateOfBirth, 'M-D-YYYY');
    defaultValues.tmp = {
      DateOfBirthMonth: dob.format('M'),
      DateOfBirthDay: dob.format('D'),
      DateOfBirthYear: dob.format('YYYY'),
    };
  } else {
    defaultValues.tmp = {
      DateOfBirthMonth: '',
      DateOfBirthDay: '',
      DateOfBirthYear: '',
    };
  }

  // console.log('defaultValues', JSON.stringify(defaultValues, null, 2));

  const methods = useForm({
    shouldFocusError: true,
    defaultValues,
  });
  const {
    handleSubmit,
    control,
    errors,
    watch,
    setError,
    clearErrors,
  } = methods;
  console.log(
    '🚀 ~ file: ContactInfo.js ~ line 464 ~ ContactInfo ~ errors',
    JSON.stringify(errors, null, 2),
  );

  const watchDisabilityCode = watch(`${baseString}.disabilityCode`);
  const watchContactStateCode = watch(`${CONTACT_BASE}.stateCode`);

  const disabled = isSubmitting;

  const kiosk = isKiosk;

  const validateEmailRule = isKiosk ? {} : isEmailRule;

  const personId = getPersonId(application, base, index);
  const documentType = getDocumentType(application, DOCUMENT_TYPE);
  const citizenshipDocumentType = getDocumentType(
    application,
    CITIZENSHIP_DOCUMENT_TYPE,
  );
  // const tenantLeaseAgreementDocumentType = getDocumentType(
  //   application,
  //   TENANT_LEASE_AGREEMENT_DOCUMENT_TYPE,
  // );

  const documentKeywordValue = documentType.keywords[DRIVERS_LICENSE_KEYWORD];

  const identityDocuments = [];
  documents &&
    documents.forEach((ele) => {
      if (
        ele &&
        ele.personId === personId &&
        ele.documentType === documentType &&
        ele.keywordValue === documentKeywordValue
      ) {
        identityDocuments.push({ uri: ele.fileDownloadURL });
      }
    });

  // const addressTypeCode = R.path([CONTACT_BASE, 'addressTypeCode'], values);

  const onSubmit = async (values) => {
    console.log('values', values);

    // const newValues = { ...application, ...values };
    const newValues = merge(JSON.parse(JSON.stringify(application)), values);
    console.log('newValues', newValues);

    if (values.tmp) {
      const {
        tmp: { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear },
      } = values;

      const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;
      const dateOfBirth = moment(dob, 'M-D-YYYY');
      // console.log('dateOfBirth.isValid()', dateOfBirth.isValid());
      // console.log('dateOfBirth.format()', dateOfBirth.format());
      console.log('dob', dob);
      if (!dateOfBirth.isValid()) {
        return setError('tmp.DateOfBirthMonth', {
          type: 'manual',
          message: 'error.invalidDate',
        });
      }

      if (index >= 0) {
        newValues[base][index].dateOfBirth = dob;
      } else {
        newValues[base].dateOfBirth = dob;
      }

      // Remove Temp Values
      delete newValues.tmp;
    }

    // Copy cityCode to city if DEFAULT_STATE_CODE
    // if (newValues[CONTACT_BASE].stateCode === DEFAULT_STATE_CODE) {
    //   newValues[CONTACT_BASE].city = R.pathOr(
    //     '',
    //     ['city', newValues[CONTACT_BASE].cityCode, 'value'],
    //     options,
    //   );
    // }

    // console.log(
    //   'ContactInfo updateApplication',
    //   JSON.stringify(newValues, null, 2)
    // );

    // const diffResults = diff(application, newValues);
    // console.log(JSON.stringify(diffResults, null, 2));
    setErrorText(null);

    setIsSubmitting(true);
    const results = await dispatch(
      updateApplicationAsync({ application: newValues }),
    );

    if (results.error) {
      setIsSubmitting(false);
      setErrorText(results.error);
      // Scroll to top
      // if (refs._scroll) refs._scroll.scrollTo({ x: 0, y: 0 });
    } else {
      // setSubmitting(false);
      // console.log('onComplete', data);
      setIsSubmitting(false);
      onComplete(results);
    }
  };

  const errorView = errorText && (
    <View style={styles.errorContainer}>
      <FormattedMessage id="error.submissionError" />
      <Text style={styles.errorText}>{errorText}</Text>
    </View>
  );

  let _scroll;
  let refs = {};

  return (
    <FormProvider {...methods}>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        ref={(scroll) => {
          _scroll = scroll;
        }}
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id={`${base}.infoTitle`}
                style={Fonts.style.title}
              />
              <FormattedMessage
                id={
                  Platform.OS === 'web' ||
                  (base !== APPLICANT_BASE && base !== CO_APPLICANT_BASE)
                    ? 'ContactInfo.subtitleWeb'
                    : 'ContactInfo.subtitle'
                }
                style={Fonts.style.subtitle}
              />
              {errorView}
            </Col>
          </Row>

          {
            /**
             * Begin Household Members Edit ONLY fields *
             *                                          */
            base === HOUSEHOLD_MEMBERS_BASE && appBase && appBase.firstName && (
              <Row>
                <Col sm={12}>
                  <HookSelectUploadDocumentType
                    base={base}
                    index={index}
                    documentType={documentType.code}
                    buttonLabelId=""
                    hideButton
                    hideDocTypes
                    multiple
                    horiz
                  />
                </Col>
              </Row>
            )
          }
          {/**
           * End Household Members Edit ONLY fields *
           *                                        */}

          {/**
           * Start Common Information *
           *                          */}
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id={
                  base === TENANTS_BASE
                    ? 'tenants.personalInformation'
                    : 'ContactInfo.contactname'
                }
                style={Fonts.style.sectionHeader}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <HookTextInput
                // inputRef={(ref) => {
                //   refs._firstName = ref;
                // }}
                control={control}
                field={`${baseString}.firstName`}
                labelId="ContactInfo.firstname"
                autoCapitalize="words"
                placeholderId="ContactInfo.firstname_placeholder"
                returnKeyType="next"
                required
                onSubmitEditing={(e) => {
                  console.log('refs', refs);
                  refs._middleName.focus();
                }}
                error={errors?.[baseString]?.firstName}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <HookTextInput
                inputRef={(ref) => {
                  refs._middleName = ref;
                }}
                control={control}
                field={`${baseString}.middleName`}
                labelId="ContactInfo.middlename"
                autoCapitalize="words"
                placeholderId="ContactInfo.middlename_placeholder"
                returnKeyType="next"
                onSubmitEditing={() => {
                  refs._lastName.focus();
                }}
                error={errors?.[baseString]?.middleName}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <HookTextInput
                inputRef={(ref) => {
                  refs._lastName = ref;
                }}
                control={control}
                field={`${baseString}.lastName`}
                labelId="ContactInfo.lastname"
                autoCapitalize="words"
                placeholderId="ContactInfo.lastname_placeholder"
                returnKeyType="next"
                onSubmitEditing={() => refs._DateOfBirthMonth.focus()}
                required
                error={errors?.[baseString]?.lastName}
                disabled={disabled}
              />
            </Col>
          </Row>
          {/**
           * End Common Information *
           *                        */}

          {/**
           * Start Everyone but Tenants *
           *                           */}
          {base !== TENANTS_BASE && (
            <View>
              <Row>
                <Col xs={12}>
                  <FormattedMessage
                    id="ContactInfo.dateofbirth"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <HookTextInput
                    inputRef={(ref) => {
                      refs._DateOfBirthMonth = ref;
                    }}
                    control={control}
                    field={`tmp.DateOfBirthMonth`}
                    labelId="ContactInfo.dateofbirthmonth"
                    placeholderId="ContactInfo.dateofbirthmonth_placeholder"
                    keyboardType="numeric"
                    returnKeyType="next"
                    maxLength={2}
                    onBlur={() => clearErrors()}
                    onMaxLength={() => refs._DateOfBirthDay.focus()}
                    onSubmitEditing={() => refs._DateOfBirthDay.focus()}
                    rules={isMonthRule}
                    error={errors?.tmp?.DateOfBirthMonth}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={4}>
                  <HookTextInput
                    inputRef={(ref) => {
                      refs._DateOfBirthDay = ref;
                    }}
                    control={control}
                    field={`tmp.DateOfBirthDay`}
                    labelId="ContactInfo.dateofbirthday"
                    placeholderId="ContactInfo.dateofbirthday_placeholder"
                    maxLength={2}
                    keyboardType="numeric"
                    returnKeyType="next"
                    onBlur={() => clearErrors()}
                    onMaxLength={() => refs._DateOfBirthYear.focus()}
                    onSubmitEditing={() => refs._DateOfBirthYear.focus()}
                    rules={isDayRule}
                    error={errors?.tmp?.DateOfBirthDay}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={4}>
                  <HookTextInput
                    inputRef={(ref) => {
                      refs._DateOfBirthYear = ref;
                    }}
                    control={control}
                    field={`tmp.DateOfBirthYear`}
                    labelId="ContactInfo.dateofbirthyear"
                    placeholderId="ContactInfo.dateofbirthyear_placeholder"
                    maxLength={4}
                    keyboardType="numeric"
                    returnKeyType="next"
                    onBlur={() => clearErrors()}
                    onMaxLength={() =>
                      refs._PrimaryPhone && refs._PrimaryPhone.focus()
                    }
                    onSubmitEditing={() =>
                      refs._PrimaryPhone && refs._PrimaryPhone.focus()
                    }
                    rules={isPastYearRule}
                    error={errors?.tmp?.DateOfBirthYear}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </View>
          )}
          {
            /**
             * Begin Applicant ONLY fields *
             *                             */
            base === APPLICANT_BASE && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormattedMessage
                      id="ContactInfo.contactinformation"
                      style={Fonts.style.sectionHeader}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <HookTextInput
                      inputRef={(ref) => {
                        refs._PrimaryPhone = ref;
                      }}
                      control={control}
                      field={`${CONTACT_BASE}.primaryPhone`}
                      labelId="ContactInfo.PrimaryPhone"
                      placeholderId="ContactInfo.PrimaryPhone_placeholder"
                      maxLength={12}
                      onMaxLength={() =>
                        refs._SecondaryPhone && refs._SecondaryPhone.focus()
                      }
                      keyboardType="phone-pad"
                      format={phoneFormat}
                      rules={isPhoneNumberRule}
                      error={errors?.[CONTACT_BASE]?.primaryPhone}
                      disabled={disabled}
                    />
                  </Col>
                  <Col sm={4}>
                    <HookCheckboxInput
                      control={control}
                      labelId="ContactInfo.PrimaryPhoneIsMobile"
                      field={`${CONTACT_BASE}.primaryPhoneIsMobile`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <HookTextInput
                      inputRef={(ref) => {
                        refs._SecondaryPhone = ref;
                      }}
                      control={control}
                      field={`${CONTACT_BASE}.secondaryPhone`}
                      labelId="ContactInfo.SecondaryPhone"
                      placeholderId="ContactInfo.SecondaryPhone_placeholder"
                      keyboardType="phone-pad"
                      maxLength={12}
                      onMaxLength={() => refs._Email && refs._Email.focus()}
                      rules={isPhoneNumberRule}
                      format={phoneFormat}
                      error={errors?.[CONTACT_BASE]?.primaryPhone}
                      disabled={disabled}
                    />
                  </Col>
                  <Col sm={4}>
                    <HookCheckboxInput
                      control={control}
                      labelId="ContactInfo.SecondaryPhoneIsMobile"
                      field={`${CONTACT_BASE}.secondaryPhoneIsMobile`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <HookTextInput
                      inputRef={(ref) => {
                        refs._Email = ref;
                      }}
                      control={control}
                      field={`${CONTACT_BASE}.email`}
                      labelId="ContactInfo.Email"
                      placeholderId="ContactInfo.Email_placeholder"
                      autoCapitalize="none"
                      keyboardType="email-address"
                      forceTouched
                      required={!kiosk}
                      rules={validateEmailRule}
                      error={errors?.[CONTACT_BASE]?.email}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field={`${CONTACT_BASE}.preferredContactMethodCode`}
                      labelId="ContactInfo.PerferredContactMethod"
                      optionsKey="contact_method"
                      required
                      error={errors?.[CONTACT_BASE]?.preferredContactMethodCode}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field={`languageCode`}
                      labelId="ContactInfo.preferredLanguageCode"
                      optionsKey="language"
                      required
                      error={errors?.languageCode}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </View>
            )

            /**
             * End Applicant ONLY fields *
             *                           */
          }
          {
            /**
             * Begin Applicant and Tenant Information *
             *                                        */
            (base === APPLICANT_BASE || base === TENANTS_BASE) && (
              <View>
                {/* {base === TENANTS_BASE && (
                <Row>
                  <Col sm={12}>
                    <FormGroupCheckbox
                      labelId="DamagedAddress.SameMailingAddress"
                      field={`copyCheckbox`}
                      onPress={this.handleMailingAddressCopy}
                    />
                  </Col>
                </Row>
              )} */}
                <Row>
                  <Col sm={12}>
                    <FormattedMessage
                      id={
                        base === TENANTS_BASE
                          ? 'tenants.mailingAddress'
                          : 'ContactInfo.mailingaddress'
                      }
                      style={Fonts.style.sectionHeader}
                    />
                  </Col>
                </Row>

                <HookAddressForm
                  addressString={
                    base === APPLICANT_BASE ? CONTACT_BASE : baseString
                  }
                />
                {watchContactStateCode === DEFAULT_STATE_CODE && (
                  <Row>
                    <Col sm={12}>
                      <HookSelectInput
                        control={control}
                        field={`${CONTACT_BASE}.countyCode`}
                        labelId="ContactInfo.county"
                        placeholderId="ContactInfo.county_placeholder"
                        optionsKey="county"
                        required
                        disabled={disabled}
                        error={errors?.[CONTACT_BASE]?.countyCode}
                      />
                    </Col>
                  </Row>
                )}

                {/* <Row>
                <Col sm={12}>
                  <FormGroupSelect
                    field={`${CONTACT_BASE}.addressTypeCode`}
                    labelId="ContactInfo.addressTypeCode"
                    placeholderId="ContactInfo.addressTypeCode_placeholder"
                    optionsKey="address_type"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              {this.renderAddress(addressTypeCode)} */}
              </View>
            )
            /**
             * End Applicant and Tenant Information *
             *                                      */
          }

          {
            /**
             * Begin Homeowner ONLY fields *
             *                             */
            base === APPLICANT_BASE && (
              <View>
                {/* <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="ContactInfo.contactinformation"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row> */}
                {/* <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field="isUSCitizenCode"
                    labelId="ProveIdentity.isUSCitizenCode"
                    optionsKey="yes_no"
                    required
                    validate={isRequired}
                    
                  />
                </Col>
              </Row> */}
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field="isLawfullyPresentInUSCode"
                      labelId="ProveIdentity.isLawfullyPresentInUSCode"
                      optionsKey="lawful_presence_type"
                      required
                      reversed
                      error={errors?.isLawfullyPresentInUSCode}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormattedMessage
                      id="ProveIdentity.citizenshipProof"
                      style={Fonts.style.subtitle}
                    />
                    <HookSelectUploadDocumentType
                      base={base}
                      index={index}
                      onComplete={() => {}}
                      documentType={citizenshipDocumentType.code}
                      buttonLabelId=""
                      hideButton
                      multiple
                      horiz
                    />
                  </Col>
                </Row>
              </View>
            )
            /**
             * End Homeowner ONLY fields *
             *                           */
          }

          {
            /**
             * Begin Homeowner, Co-Homeowner and Household Members ONLY fields *
             *                                                                 */
            (base === APPLICANT_BASE ||
              base === CO_APPLICANT_BASE ||
              base === HOUSEHOLD_MEMBERS_BASE) &&
              !isRentalProgram && (
                <View>
                  <Row>
                    <Col sm={12}>
                      <FormattedMessage
                        id="ContactInfo.demographicInformation"
                        style={Fonts.style.sectionHeader}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <HookRadioInput
                        control={control}
                        field={`${baseString}.genderCode`}
                        labelId="ContactInfo.gender"
                        optionsKey="gender"
                        required
                        error={objectByString(
                          errors,
                          `${baseString}.genderCode`,
                        )}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <HookRadioInput
                        control={control}
                        field={`${baseString}.raceCode`}
                        labelId="ContactInfo.race"
                        optionsKey="race"
                        required
                        error={objectByString(errors, `${baseString}.raceCode`)}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <HookRadioInput
                        control={control}
                        field={`${baseString}.ethnicityCode`}
                        labelId="ContactInfo.ethnicity"
                        optionsKey="ethnicity"
                        required
                        error={objectByString(
                          errors,
                          `${baseString}.ethnicityCode`,
                        )}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <HookRadioInput
                        control={control}
                        field={`${baseString}.disabilityCode`}
                        labelId="ContactInfo.disabilityCode"
                        optionsKey="disability"
                        required
                        error={objectByString(
                          errors,
                          `${baseString}.disabilityCode`,
                        )}
                        reverse
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  {watchDisabilityCode === YES_OPTION && (
                    <Row>
                      <Col sm={12}>
                        <HookRadioInput
                          control={control}
                          field={`${baseString}.specialAccomodationsRequiredCode`}
                          labelId="ContactInfo.specialAccomodationsRequiredCode"
                          optionsKey="yes_no"
                          required
                          error={objectByString(
                            errors,
                            `${baseString}.specialAccomodationsRequiredCode`,
                          )}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm={12}>
                      <HookRadioInput
                        control={control}
                        field={`${baseString}.isVeteranCode`}
                        labelId="ContactInfo.isVeteranCode"
                        optionsKey="yes_no"
                        required
                        error={objectByString(
                          errors,
                          `${baseString}.isVeteranCode`,
                        )}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <HookRadioInput
                        control={control}
                        field={`${baseString}.isWoundedWarriorCode`}
                        labelId="ContactInfo.isWoundedWarriorCode"
                        optionsKey="yes_no"
                        required
                        error={objectByString(
                          errors,
                          `${baseString}.isWoundedWarriorCode`,
                        )}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                </View>
              )
            /**
             * End Homeowner, Co-Homeowner and Household Members ONLY fields *
             *                                                                 */
          }

          {
            /**
             * Begin Homeowner ONLY fields *
             *                             */
            base === APPLICANT_BASE && !isRentalProgram && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormattedMessage
                      id="ContactInfo.incomeInformation"
                      style={Fonts.style.sectionHeader}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field="householdIncomeCode"
                      labelId="ContactInfo.income"
                      optionsKey="household_income"
                      required
                      error={errors?.householdIncomeCode}
                    />
                  </Col>
                </Row>
              </View>
            )
            /**
             * End Homeowner ONLY fields *
             *                           */
          }

          {/**
             * Begin Tenant Rental ONLY fields *
             *                                
            base === TENANTS_BASE && isRentalProgram && (
              <View>
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field={`${baseString}.tenantLivedAtPropertyDuringDisasterCode`}
                      labelId="tenants.tenantLivedAtPropertyDuringDisasterCode"
                      optionsKey="yes_no"
                      required
                      error={objectByString(
                        errors,
                        `${baseString}.tenantLivedAtPropertyDuringDisasterCode`,
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field={`${baseString}.tenantWasDisplacedFromPropertyByDisasterCode`}
                      labelId="tenants.tenantWasDisplacedFromPropertyByDisasterCode"
                      optionsKey="yes_no"
                      required
                      error={objectByString(
                        errors,
                        `${baseString}.tenantWasDisplacedFromPropertyByDisasterCode`,
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <HookRadioInput
                      control={control}
                      field={`${baseString}.tenantCurrentlyLivesAtPropertyCode`}
                      labelId="tenants.tenantCurrentlyLivesAtPropertyCode"
                      optionsKey="yes_no"
                      required
                      error={objectByString(
                        errors,
                        `${baseString}.tenantCurrentlyLivesAtPropertyCode`,
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormattedMessage
                      id="tenants.tenantLeaseAgreement"
                      style={Fonts.style.subtitle}
                    />
                    <UploadDocumentType
                      base={base}
                      index={index}
                      documentType={tenantLeaseAgreementDocumentType.code}
                      // documentKeywordValue={DOCUMENT_KEYWORD_VALUE}
                    />
                  </Col>
                </Row>
              </View>
            )
            /**
             * End Homeowner ONLY fields *
             *                           */}

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                disabled={isSubmitting}
                onPress={handleSubmit(onSubmit)}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    </FormProvider>
  );
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

// ContactInfo = withFormik({
//   mapPropsToValues: (props) => {
//     const {
//       base,
//       index,
//       application,
//       token,
//       account,
//       isKiosk,
//       options,
//     } = props;
//     const appBase = getBase(application, base, index);

//     const newApplication = JSON.parse(JSON.stringify(application));
//     // console.log('mapPropsToValues', JSON.stringify(newApplication, null, 2));

//     if (!newApplication[CONTACT_BASE]) {
//       newApplication[CONTACT_BASE] = {};
//     }

//     // Set Default State
//     if (
//       !newApplication ||
//       !newApplication[CONTACT_BASE] ||
//       !newApplication[CONTACT_BASE].stateCode
//     ) {
//       // console.log('setting default state', CONTACT_BASE, DEFAULT_STATE_CODE);
//       newApplication[CONTACT_BASE].stateCode = DEFAULT_STATE_CODE;
//       // change(`${CONTACT_BASE}.stateCode`, DEFAULT_STATE_CODE);
//     }

//     // Copy City String to City Code
//     // if (
//     //   newApplication[CONTACT_BASE].stateCode === DEFAULT_STATE_CODE &&
//     //   newApplication[CONTACT_BASE].city &&
//     //   options &&
//     //   options.city
//     // ) {
//     //   Object.keys(options.city).forEach((cityCode) => {
//     //     const city = options.city[cityCode];
//     //     if (city.value === newApplication[CONTACT_BASE].city) {
//     //       newApplication[CONTACT_BASE].cityCode = cityCode;
//     //     }
//     //   });
//     // }

//     if (base === APPLICANT_BASE) {
//       // // Split Address
//       // if (
//       //   newApplication[CONTACT_BASE] &&
//       //   newApplication[CONTACT_BASE].address
//       // ) {
//       //   const split = newApplication[CONTACT_BASE].address.split('\n');

//       //   newApplication[CONTACT_BASE].address1 = split[0];
//       //   newApplication[CONTACT_BASE].address2 = split[2];
//       //   // change(`${CONTACT_BASE}.address1`, split[0]);
//       //   // change(`${CONTACT_BASE}.address2`, split[1]);
//       // }

//       // Set Default EMail if not Set
//       if (
//         !isKiosk &&
//         token &&
//         account.emailAddress &&
//         (!newApplication[CONTACT_BASE] || !newApplication[CONTACT_BASE].email)
//       ) {
//         newApplication[CONTACT_BASE].email = account.emailAddress;
//         // change(`${CONTACT_BASE}.email`, account.emailAddress);
//       }
//     }

//     if (base === TENANTS_BASE) {
//       // Copy City String to City Code
//       // if (
//       //   newApplication[TENANTS_BASE][index].stateCode === DEFAULT_STATE_CODE &&
//       //   newApplication[TENANTS_BASE][index].city &&
//       //   options &&
//       //   options.city
//       // ) {
//       //   Object.keys(options.city).forEach((cityCode) => {
//       //     const city = options.city[cityCode];
//       //     if (city.value === newApplication[TENANTS_BASE][index].city) {
//       //       newApplication[TENANTS_BASE][index].cityCode = cityCode;
//       //     }
//       //   });
//       // }
//     }

//     if (appBase && appBase.dateOfBirth) {
//       const dob = moment(appBase.dateOfBirth, 'M-D-YYYY');
//       newApplication.tmp = {
//         DateOfBirthMonth: dob.format('M'),
//         DateOfBirthDay: dob.format('D'),
//         DateOfBirthYear: dob.format('YYYY'),
//       };
//       // change('DateOfBirthMonth', dob.format('M'));
//       // change('DateOfBirthDay', dob.format('D'));
//       // change('DateOfBirthYear', dob.format('YYYY'));
//       // console.log('loading DOB', appBase.dateOfBirth, dob.format('MM-DD-YYYY'));
//     } else {
//       newApplication.tmp = {
//         DateOfBirthMonth: '',
//         DateOfBirthDay: '',
//         DateOfBirthYear: '',
//       };
//       // change('DateOfBirthMonth', '');
//       // change('DateOfBirthDay', '');
//       // change('DateOfBirthYear', '');
//     }

//     // console.log('loaded application', JSON.stringify(newApplication, null, 2));
//     return newApplication;
//   },

//   validate: (values, props) => {
//     // console.log('validating', values);
//     const errors = {};
//     // if (
//     //   R.path(['contact', 'preferredContactMethod'], values) &&
//     //   values.contact.preferredContactMethod === '3' &&
//     //   !R.path(['contact', 'email'], values)
//     // ) {
//     //   errors[CONTACT_BASE] = {};
//     //   errors[CONTACT_BASE].email = 'emailRequiredMethod';
//     // }
//     const { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear } = values.tmp;
//     if (DateOfBirthMonth && DateOfBirthDay && DateOfBirthYear) {
//       const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;
//       const dateOfBirth = moment(dob, 'M-D-YYYY');
//       if (!dateOfBirth.isValid()) {
//         errors.DateOfBirth = 'error.invalidDate';
//       }
//     }

//     return errors;
//   },
//   // isInitialValid: (props) => true,
//   // validationSchema,
//   // Submission handler
//   handleSubmit: (values, { setStatus, props, dirty }) => {
//     const { application, base, index, options } = props;

//     // let newValues = Immutable.asMutable(values) // Does not work
//     const newValues = { ...values };

//     const {
//       tmp: { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear },
//     } = values;

//     const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;

//     if (index >= 0) {
//       newValues[base][index].dateOfBirth = dob;
//     } else {
//       newValues[base].dateOfBirth = dob;
//     }

//     // Remove Temp Values
//     delete newValues.tmp;

//     // Copy cityCode to city if DEFAULT_STATE_CODE
//     // if (newValues[CONTACT_BASE].stateCode === DEFAULT_STATE_CODE) {
//     //   newValues[CONTACT_BASE].city = R.pathOr(
//     //     '',
//     //     ['city', newValues[CONTACT_BASE].cityCode, 'value'],
//     //     options,
//     //   );
//     // }

//     // console.log(
//     //   'ContactInfo updateApplication',
//     //   JSON.stringify(newValues, null, 2)
//     // );

//     // const diffResults = diff(application, newValues);
//     // console.log(JSON.stringify(diffResults, null, 2));

//     props.updateApplication(newValues);
//     setStatus('updatingApplication');
//   },
// })(ContactInfo);

// const mapStateToProps = ({
//   app: { initilized },
//   application: { fetching, error, data },
//   persist: { application, account, token, tokenId, isKiosk, options },
//   document,
//   form,
// }) => ({
//   account,
//   isKiosk,
//   options,
//   token,
//   tokenId,
//   errorText: error,
//   fetching,
//   data,
//   initilized,
//   application,
//   documents: document.documents,
//   documentsError: document.error,
//   documentsFetching: document.fetching,
//   initialValues: application,
//   // currentForm: form.ContactInfo
// });

// const mapDispatchToProps = (dispatch) => ({
//   updateApplication: (application) =>
//     dispatch(ApplicationActions.ApplicationUpdate(application)),
//   loadDocuments: (docType) =>
//     dispatch(DocumentActions.DocumentLoadDocuments(docType)),
//   uploadDocuments: (
//     personId,
//     documents,
//     documentTypeCode,
//     keywordValueCode,
//     documentId,
//   ) =>
//     dispatch(
//       DocumentActions.DocumentUpload(
//         personId,
//         documents,
//         documentTypeCode,
//         keywordValueCode,
//         documentId,
//       ),
//     ),
//   deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
//     dispatch(
//       DocumentActions.DocumentDelete(
//         personId,
//         documentTypeCode,
//         keywordValueCode,
//       ),
//     ),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
