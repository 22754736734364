import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  CasesGet: null,
  CaseLink: ['userPortalCaseLinkCode'],

  CaseSet: ['application'],

  CaseSuccess: ['data'],
  CaseFailure: ['error'],

  CaseReset: null,
});

export const CaseTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  application: null,
  data: null,
  error: null,
  fetching: false,
});

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = (state) =>
  state.merge({ error: null, fetching: true, application: null, action: null });

// we've successfully completed an API call.
export const success = (state, { data, action }) =>
  state.merge({ fetching: false, error: null, data, action });

export const caseSet = (state, { application }) => state.merge({ application });

// we've had a problem with our API call.
export const failure = (state, { error, action }) =>
  state.merge({
    fetching: false,
    error: error || 'Unknown Error',
    data: null,
    action,
  });

export const reset = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CASES_GET]: request,
  [Types.CASE_LINK]: request,

  [Types.CASE_SET]: caseSet,

  [Types.CASE_SUCCESS]: success,
  [Types.CASE_FAILURE]: failure,

  [Types.CASE_RESET]: reset,
});

/* ------------- Selectors ------------- */
